import { useState, createContext, useRef, useContext, FC, ReactNode, useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { filterAllNarrationsIntoGroupedArrays } from '../../helpers/narrationHelpers';
import { matchMutate, useSwrNarrations } from '../../helpers/swrHelpers';
import { GlobalNarrationContextInterface, NarrationInterface } from '../../types/types';
import { useAuth } from '../AuthContext';

export const GlobalNarrationContext = createContext<GlobalNarrationContextInterface>({
    staticallyFetchedNarrations: [],
    setStaticallyFetchedNarrations: () => {},
    successAndDraftNarrations: undefined,
    successNarrations: undefined,
    successNarrationsLast30Days: undefined,
    draftNarrations: undefined,
    audioStudioNarrations: undefined,
    calendlySectionRef: null,
    mutateNarrations: () => {}
});

// hook that we can use anywhere in the app
export const useGlobalNarrationContext = () => useContext(GlobalNarrationContext);

const GlobalNarrationProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { project, user } = useAuth();

    const [staticallyFetchedNarrations, setStaticallyFetchedNarrations] = useState<NarrationInterface[]>([]);

    // * set initial state as undefined so that we can show a loader until the search for narrations is completed
    // * successAndDraftNarrations is its own state var rather than combining the other two because it is ordered by creationDate
    const [successAndDraftNarrations, setSuccessAndDraftNarrations] = useState<NarrationInterface[] | undefined>(undefined);
    const [successNarrations, setSuccessNarrations] = useState<NarrationInterface[] | undefined>(undefined);
    const [successNarrationsLast30Days, setSucessNarrationsLast30Days] = useState<NarrationInterface[] | undefined>(undefined);
    const [draftNarrations, setDraftNarrations] = useState<NarrationInterface[] | undefined>(undefined);
    const [audioStudioNarrations, setAudioStudioNarrations] = useState<NarrationInterface[] | undefined>(undefined);

    // * setting limit as 100,000 as overkill, since we want to fetch all narrations from the publication.
    // * On the b2b API we currently default to a limit of 25, if that changes then this limit can be changed accordingly
    const { narrations: allNarrations } = useSwrNarrations(project?.id || null, 100000, 0);
    const { cache, mutate } = useSWRConfig();

    // * taken from b2b splash page app
    const calendlySectionRef: any = useRef();

    useEffect(() => {
        filterAllNarrationsIntoGroupedArrays({
            allNarrations,
            setSuccessAndDraftNarrations,
            setSuccessNarrations,
            setDraftNarrations,
            setAudioStudioNarrations,
            setSucessNarrationsLast30Days
        });
    }, [allNarrations]);

    const resetGlobalNarrationContext = () => {
        setStaticallyFetchedNarrations([]);
        setSuccessAndDraftNarrations(undefined);
        setSuccessNarrations(undefined);
        setSucessNarrationsLast30Days(undefined);
        setDraftNarrations(undefined);
        setAudioStudioNarrations(undefined);
    };

    useEffect(() => {
        if (!user) {
            // * reset context if user logs out
            resetGlobalNarrationContext();
        }
    }, [user]);

    // revalidate swr to remove narration from UI. The Regex matches the beginning of the api route used in useSwrNarrations
    const mutateNarrations = () => matchMutate(/^\/api\/v2\/narrations\/get-narrations/, cache, mutate);

    return (
        <GlobalNarrationContext.Provider
            value={{
                staticallyFetchedNarrations,
                setStaticallyFetchedNarrations,
                successAndDraftNarrations,
                successNarrations,
                successNarrationsLast30Days,
                draftNarrations,
                audioStudioNarrations,
                calendlySectionRef,
                mutateNarrations
            }}
        >
            {children}
        </GlobalNarrationContext.Provider>
    );
};

export default GlobalNarrationProvider;
