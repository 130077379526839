import { User } from 'firebase/auth';
import { initSentryUser } from '../config/sentry/sentry';
import { instantiatePostHog } from '../config/postHog/postHog';
import { initUserflowUser } from '../config/userflow/userflow';
import { captureException } from '@sentry/nextjs';
import { initCannyUser } from './cannyHelpers';

const telemetryServices = [
    { serviceName: 'Sentry', init: initSentryUser },
    { serviceName: 'PostHog', init: instantiatePostHog },
    { serviceName: 'Userflow', init: initUserflowUser },
    { serviceName: 'Canny', init: initCannyUser }
];

export const initTelemetryUser = (user: User) => {
    telemetryServices.forEach(({ serviceName, init }) => {
        try {
            init(user);
        } catch (error) {
            const { message, cause } = formatSentryError(`Error initializing ${serviceName} user`, 'initTelemetryUser', error);
            captureException(new Error(message, cause));
        }
    });
};

// context: additional context info about the error
// component: additional context about where the error is coming from
// error: the caught error
export const formatSentryError = (context: string, component: string, error: unknown) => {
    const message = formatSentryMessage(context, component);
    const cause = { cause: error as Error };

    return { message, cause };
};

// use when you want to log an error in sentry in a location where no exception was caught
export const formatSentryMessage = (context: string, component: string) => {
    return `${context} - via ${component}`;
};
