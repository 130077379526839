import { captureException } from '@sentry/nextjs';
import { formatSentryError } from './telemetryHelpers';
import { REGISTER_EVENT_API_URL, EVENT_NAME } from '../utils/constants/postHogConstants';
import { appVersion, APP_PROVENANCE } from '../utils/constants/appMetadataConstants';
import posthog from 'posthog-js';

const registerPosthogEvent = async (event: EVENT_NAME, properties?: {}): Promise<boolean> => {
    try {
        let payload;

        // * always add appVersion, appProvenance, and appEnvironment to posthog events
        const constantEventProps: any = { appVersion, appProvenance: APP_PROVENANCE, appEnvironment: process.env.APP_ENV };

        // * attach current url in same way that is used by client side posthog calls
        if (window?.location?.href) {
            constantEventProps['$current_url'] = window.location.href;
        }

        if (properties) {
            properties = { ...properties, ...constantEventProps };
        } else {
            properties = constantEventProps;
        }

        payload = {
            event,
            properties
        };

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const response = await fetch(REGISTER_EVENT_API_URL, reqOptions);
        if (!response.ok || response.status != 200) {
            throw new Error(`Error registering event to Posthog|${response.status}|${response.statusText}`);
        }

        // * reload feature flags if user properties have been set
        if (properties && ('$set' in properties || '$set_once' in properties)) {
            posthog.reloadFeatureFlags();
        }

        return true;
    } catch (error: unknown) {
        const { message, cause } = formatSentryError('Error while attempting to log event to Posthog', 'registerPosthogEvent', error);
        captureException(new Error(message, cause));
        return false;
    }
};

export { registerPosthogEvent };
