import { defineStyleConfig } from '@chakra-ui/react';

export const tabTheme = defineStyleConfig({
    variants: {
        line: {
            tab: {
                _hover: {
                    bgColor: 'brand.grayOnBlack',
                    _disabled: {
                        bgColor: 'transparent'
                    },
                    borderBottom: '2px',
                    borderColor: 'white'
                },
                _selected: { textColor: 'brand.500' },
                textColor: 'inherit',
                alignItems: 'flex-end',
                fontSize: 'subheading1'
            },
            tablist: {
                borderColor: 'brand.darkComponentOutline'
            }
        }
    },
    defaultProps: {
        variant: 'line'
    }
});
