import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import useSWR from 'swr';
import { ScopedMutator, Cache } from 'swr/dist/types';
import { NarrationInterface, UrlType } from '../types/types';
import { formatSentryMessage } from './telemetryHelpers';

export const fetcher = (url: UrlType) => axios.get(url).then((res) => res.data);

export const useSwrNarrations = (projectId: string | null, limit?: number, offset?: number) => {
    // conditional fetch https://swr.vercel.app/docs/conditional-fetching#conditional
    const { data, error, mutate } = useSWR(
        projectId ? `/api/v2/narrations/get-narrations/${projectId}?limit=${limit}&offset=${offset}` : null,
        fetcher
    );

    return {
        narrations: data as NarrationInterface[],
        isLoadingNarrations: !error && !data,
        isErrorNarrations: error,
        mutateNarrations: mutate
    };
};

// Modified function from https://swr.vercel.app/docs/advanced/cache#mutate-multiple-keys-from-regex
export const matchMutate = (matcher: RegExp, cache: Cache<any>, mutate: ScopedMutator<any>) => {
    if (!(cache instanceof Map)) {
        const message = formatSentryMessage(
            'could not delete narration in swr - matchMutate requires the cache provider to be a Map instance',
            'useMatchMutate'
        );
        captureException(new Error(message));
        return;
    }

    const keys = [];

    if (cache.keys()) {
        for (const key of Array.from(cache.keys())) {
            if (matcher.test(key)) {
                keys.push(key);
            }
        }
    }

    if (keys) {
        keys.map((key: RegExp) => mutate(key));
    }

    return;
};
