import { Flex, FlexProps, LinkProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { ALL_ROUTES } from '../../../types/types';
import { EVENT_NAME } from '../../../utils/constants/postHogConstants';

interface NavItemProps {
    children: ReactNode;
    route: ALL_ROUTES;
    dataTestId: string;
    callbackBeforeRouting?: () => boolean; // * the return type determines if the routing should continue
    disableHighlight?: boolean;
    additionalProps?: FlexProps & LinkProps & any;
}

export const NavItem = ({ children, dataTestId, route, callbackBeforeRouting, disableHighlight = false, additionalProps }: NavItemProps) => {
    const { registerPosthogEventWithAuthProps } = useAuth();
    const router = useRouter();

    const onClick = () => {
        registerPosthogEventWithAuthProps(EVENT_NAME.sidebarNavItemClick, {
            featureName: dataTestId
        });

        if (callbackBeforeRouting) {
            const shouldContinueWithRouting = callbackBeforeRouting();
            if (!shouldContinueWithRouting) return;
        }

        router.push(route);
    };

    const textColour = useMemo(() => {
        if (router?.pathname?.startsWith(route) && route !== '/' && !disableHighlight) {
            return 'brand.500';
        } else return 'white';
        // ignore warning missing dependency. Resolving results in unnecessary re-renders
    }, [router?.pathname, route]); // eslint-disable-line

    return (
        <Flex
            align="center"
            px="4"
            pl="8"
            py="3"
            cursor={'pointer'}
            color={textColour}
            _hover={{
                bg: 'brand.grayOnBlack'
            }}
            fontWeight="semibold"
            onClick={onClick}
            data-testid={dataTestId}
            fontSize="subheading2"
            {...additionalProps}
        >
            {children}
        </Flex>
    );
};
