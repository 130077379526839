import { AppProps } from 'next/app';
import Head from 'next/head';
import { MetaTagsInterface } from '../../types/types';
import { defaultMetaTags } from '../../helpers/metaHelpers';
import Script from 'next/script';

export default function MetaHead(appProps: AppProps) {
    let metaTags: MetaTagsInterface = defaultMetaTags;

    if (appProps.pageProps) {
        const pageProps = appProps.pageProps;

        if (pageProps.metaTags) {
            metaTags = pageProps.metaTags;
        }
    }

    return (
        <>
            <Head>
                <title>{metaTags.title}</title>
                <meta name="description" content={metaTags.description} />
                <link rel="icon" href="/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:title" content={metaTags.ogTitle} />
                <meta property="og:description" content={metaTags.ogDescription} />
                <meta property="og:image" content={metaTags.ogImage} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@AdAuris_audio" />
                <meta name="twitter:title" content={metaTags.ogTitle} />
                <meta name="twitter:description" content={metaTags.ogDescription} />
                <meta name="twitter:image" content={metaTags.ogImage} />
            </Head>

            {/* <!-- Global site tag (gtag.js) - Google Analytics -->  */}
            <Script async src="https://www.googletagmanager.com/gtag/js?id=G-2TLFBXKKS1"></Script>
            <Script
                id="google-analytics-script"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2TLFBXKKS1', {
                page_path: window.location.pathname,
            });
            `
                }}
            />

            <Script id="hs-script-loader" async defer src="//js.hs-scripts.com/44811461.js" />

            <Script
                // docs here: https://developers.canny.io/install
                // user is identified in helpers/telemetryHelpers.ts
                id="canny-identify-loader"
                dangerouslySetInnerHTML={{
                    __html: `!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");`
                }}
            />

            <Script
                // Twitter conversion tracking base code
                // https://business.x.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
                id="twitter-conversion-tracking"
                dangerouslySetInnerHTML={{
                    __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
                    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                    twq('config','omyrh');`
                }}
            />

            <Script src="https://r.wdfl.co/rw.js" data-rewardful="48c717"></Script>
            <Script id="rewardful-queue" strategy="beforeInteractive">
                {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
            </Script>
        </>
    );
}
