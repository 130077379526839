// theme.ts
// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { buttonTheme } from './buttonTheme';
import { linkTheme } from './linkTheme';
import { tabTheme } from './tabTheme';
import '@fontsource/manrope';
import { inputTheme } from './inputTheme';
import { textareaTheme } from './textareaTheme';
import { stepperTheme } from './stepperTheme';
import { modalTheme } from './modalTheme';
import { selectTheme } from './selectTheme';

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false
};

// * Adauris default colors are displayed here https://www.figma.com/file/wN7NpccVaxcMBIqLAaWlJf/B2B-Dashboard?node-id=941-98&t=Gi2kRQPEOOcSI3aa-0
const colors = {
    brand: {
        400: '#F2FBDD', // * tertiary colour
        500: '#69E23F', // * primary colour - default chakra main
        600: '#3AE172', // * secondary colour - default chakra hover,
        darkComponentBg: '#171717',
        darkComponentOutline: '#373736',
        whiteGreen: '#F5FBF4',
        grayOnBlack: '#2F2F2E',
        primaryGradientColor1: '#38E073',
        primaryGradientColor2: '#8BF450',
        lightGradientColor1: '#58E587',
        lightGradientColor2: '#9FFC70',
        darkGradientColor1: '#0F2F23',
        darkGradientColor2: '#161918'
    },
    green: {
        200: '#3AE172' // * same as brand.600/secondary colour - default for chakra toasts and stepper indicator
    },
    red: {
        350: '#D9746F',
        500: '#FF4141',
        infoCard: '#FFB6AF'
    },
    gray: {
        offWhite: '#F9FAFC',
        750: '#363636',
        808080: '#808080',
        text: '#8C8C8C'
    },
    blue: {
        link: '#0066cc'
    },
    warning: '#a38f3e',
    error: '#D91A1A',
    info: '#BEE3F8',
    // * form colors are the default chakra form colors, found by inspecting element
    form: {
        font: 'white',
        border: 'rgb(226, 232, 240)'
    },
    black: '#121211'
};

// * named duplicate sizes match typography from blackpeak https://linear.app/ad-auris/issue/ENG-332/adjust-font-across-sign-up-pages
// * defaults: https://chakra-ui-git-fix-typescript-autocomplete.chakra-ui.vercel.app/docs/theming/theme#typography
const fontSizes = {
    subheading2: '14px',
    body: '16px',
    subheading1: '18px',
    heading2: '48px',
    heading1: '60px',
    heading1Mobile: '36px'
};

const fonts = {
    heading: `'Manrope', sans-serif`,
    body: `'Manrope', sans-serif`
};

const shadows = {
    outlineWhite: '0 0 0 3px rgba(255, 255, 225, 0.6)',
    outlineDarkGray: '0 0 0 3px rgba(204, 204, 204, 0.6)',
    outlineLightGray: '0 0 0 3px rgba(236, 236, 236, 0.6)',
    mdWhite: '0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06)',
    smPrimaryGreen: '0 0 0 1px rgba(168,233,184, 0.8)',
    outlinePrimaryGreen: '0 0 0 3px rgba(168,233,184, 0.8)'
};

const breakpoints = {
    '425px': '26.6em' // breakpoint used by react-h5-audio-player at 425px
};

const styles = {
    global: (props: any) => ({
        body: {
            bg: '#121211',
            color: 'white'
        }
    })
};

// 3. extend the theme
const theme = extendTheme({
    config,
    colors,
    fonts,
    fontSizes,
    shadows,
    breakpoints,
    styles,
    defaultProps: {
        colorScheme: 'brand'
    },
    /* useful docs for components
     * https://chakra-ui.com/docs/styled-system/component-style#styling-multipart-components
     * https://github.com/chakra-ui/chakra-ui/discussions/2452
     *
     * TLDR: theme setup varies depending if the component is singular vs multipart (see buttonTheme.ts vs tabTheme.ts).
     * The variant you modify should match the variant name from chakra's source code.
     * I've found modifying the baseStyles works for components that don't use variants
     */
    components: {
        Button: buttonTheme,
        Tabs: tabTheme,
        Link: linkTheme,
        Input: inputTheme,
        Textarea: textareaTheme,
        Stepper: stepperTheme,
        Modal: modalTheme,
        Select: selectTheme
    }
});

export default theme;
