// * This container allows us to access some context variables within _app level

import { Box, ScaleFade } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import { useEffect, useMemo } from 'react';
import DashboardWrapper from '../../components/DashboardWrapper/DashboardWrapper';
import { ShareNarrationFooter } from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import { useAuth } from '../../context/AuthContext';
import FeatureFlagsProvider, { useFeatureFlagsContext } from '../../context/hooks/FeatureFlagsHook';
import { ALL_ROUTES } from '../../types/types';
import { NAVBAR_HEIGHT } from '../../utils/constants/stylingConstants';

// * include routes that can be accessed without being logged in
const noAuthRequired: string[] = [
    ALL_ROUTES.AUTH_PAGE,
    ALL_ROUTES.SIGNIN,
    ALL_ROUTES.SIGNUP,
    ALL_ROUTES.ONBOARDING,
    ALL_ROUTES.SHARE_NARRATION,
    ALL_ROUTES.RESET_PASSWORD
];

// * include routes from noAuthRequired that should also be accessible within the dashboard wrapper when logged in
const accessibleWithAndWithoutAuth: string[] = [];

// * include routes that need to bypass the dashboard wrapper altogether
const noDashboardWrapperOrNavbar: string[] = [ALL_ROUTES.SHARE_NARRATION, ALL_ROUTES.RESET_PASSWORD];

function AppComponentContainer({ Component, pageProps, router }: AppProps) {
    const { user } = useAuth();
    const { refreshAllFeatureFlags } = useFeatureFlagsContext();

    const showLoggedOutNavbarLayout = useMemo(() => {
        return noAuthRequired.includes(router.pathname) && !(accessibleWithAndWithoutAuth.includes(router.pathname) && user);
    }, [router.pathname, user]);

    useEffect(() => {
        // * ensure feature flags are set properly for the new user
        refreshAllFeatureFlags();
        // ignore warning missing dependency. Resolving results in unnecessary re-renders
    }, [user]); // eslint-disable-line

    return (
        <FeatureFlagsProvider>
            <ScaleFade key={router.route} initialScale={0.9} in={true}>
                {showLoggedOutNavbarLayout ? (
                    <>
                        <NavBar showProductDemoButton={router.pathname === ALL_ROUTES.SHARE_NARRATION ? true : false} />
                        <Box pt={NAVBAR_HEIGHT} w="100%">
                            <Component {...pageProps} />
                        </Box>
                    </>
                ) : noDashboardWrapperOrNavbar.includes(router.pathname) ? (
                    <Component {...pageProps} />
                ) : (
                    <DashboardWrapper content={<Component {...pageProps} />} />
                )}
            </ScaleFade>
            {router.pathname === ALL_ROUTES.SHARE_NARRATION && <ShareNarrationFooter />}
        </FeatureFlagsProvider>
    );
}

export default AppComponentContainer;
