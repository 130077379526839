import { defineStyleConfig } from '@chakra-ui/react';

export const modalTheme = defineStyleConfig({
    baseStyle: {
        dialog: {
            bgColor: 'brand.darkComponentBg',
            outlineColor: 'brand.darkComponentOutline',
            outline: '1px solid'
        }
    }
});
