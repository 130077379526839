import { User } from 'firebase/auth';
import { captureException, captureMessage } from '@sentry/nextjs';
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import {
    FirestoreDocumentIdType,
    PodcastChannelInterface,
    PodcastChannelPayloadInterface,
    PodcastMetadataInterface,
    PodcastPlatformUrl,
    PodcastRssFormInterface,
    PodcastSetupInterface,
    PodcastSetupStatusEnum,
    UserPreferencesPodcastSetupInterface
} from '../types/types';
import { GOOGLE_ADD_RSS_FEED_URL } from '../utils/constants/constants';
import {
    APPLE_PODCASTS_METADATA,
    GOOGLE_PODCASTS_METADATA,
    SPOTIFY_PODCASTS_METADATA,
    SPOTIFY_SUBMIT_LINK
} from '../utils/constants/podcastConstants';
import { EVENT_NAME } from '../utils/constants/postHogConstants';
import { registerPosthogEvent } from './postHogEventHelpers';
import { openNewTab } from './tabHelpers';
import { formatSentryError, formatSentryMessage } from './telemetryHelpers';

export const goToSpotify = () => {
    openNewTab(SPOTIFY_SUBMIT_LINK);
    registerPosthogEvent(EVENT_NAME.clickLink, {
        href: SPOTIFY_SUBMIT_LINK,
        currentLocation: window.location.href
    });
};

export const onGoogleSubscribe = () => {
    openNewTab(GOOGLE_ADD_RSS_FEED_URL);
    registerPosthogEvent(EVENT_NAME.clickLink, {
        href: GOOGLE_ADD_RSS_FEED_URL,
        currentLocation: window.location.href
    });
};

export const getApplePodcastLink = (podcastChannelId: FirestoreDocumentIdType) => {
    return `podcast://${process.env.B2B_PODCAST_HOST_RSS_FEED}/${podcastChannelId}/rss.xml`;
};

export const onAppleFollow = (user: User | null) => {
    if (user?.uid) {
        const link = getApplePodcastLink(user.uid);
        openNewTab(link);

        registerPosthogEvent(EVENT_NAME.clickLink, {
            href: link,
            currentLocation: window.location.href
        });
    } else {
        // this should never happen since isLoading shouldnt allow for the button to show
        const message = formatSentryMessage('Cannot create link to RSS feed | user is null', 'AppleSetupView');
        captureMessage(message);
    }
};

export const getPodcastChannelId = async (projectId: FirestoreDocumentIdType) => {
    try {
        const response = await axios.get(`/api/v2/podcasts/get-channel/project/${projectId}`);
        const newChannelId = response.data[0].id as FirestoreDocumentIdType;
        return newChannelId;
    } catch (err: any) {
        return undefined;
    }
};

export const getPodcastChannel = async (projectId: FirestoreDocumentIdType): Promise<PodcastChannelInterface | null> => {
    try {
        const response = await axios.get(`/api/v2/podcasts/get-channel/project/${projectId}`);
        if (!Array.isArray(response.data)) {
            return null;
        }
        const channel = response.data[0] as PodcastChannelInterface;
        return channel;
    } catch (err: any) {
        throw err;
    }
};

export const updatePodcastChannel = async (podcastChannelId: FirestoreDocumentIdType, payload: Partial<PodcastChannelPayloadInterface>) => {
    try {
        const response = await axios.patch(`/api/v2/podcasts/update-channel/${podcastChannelId}`, payload);
        const channelId = response.data.id as FirestoreDocumentIdType;
        return channelId;
    } catch (err: any) {
        throw err;
    }
};

export const createPodcastChannel = async (payload: PodcastChannelPayloadInterface) => {
    try {
        const response = await axios.post('/api/v2/podcasts/create-channel', payload);
        const channelId = response.data.id as FirestoreDocumentIdType;
        return channelId;
    } catch (err: any) {
        throw err;
    }
};

export const addPodcastEpisode = async (channelId: FirestoreDocumentIdType, narrationId: FirestoreDocumentIdType) => {
    const podcastEpisodePayload = {
        narrationId,
        channelId
    };
    try {
        const response = await axios.post(`/api/v2/podcasts/postEpisode/`, podcastEpisodePayload);
        const newChannelId = response.data[0].id;
        return newChannelId;
    } catch (err: any) {
        return undefined;
    }
};

export const getSpotifyUrlbyUid = async (uid: FirestoreDocumentIdType): Promise<PodcastPlatformUrl> => {
    try {
        const response = await axios.get(`/api/v2/users/getPodcastSetup/${uid}`);
        const podcastSetup = response.data as UserPreferencesPodcastSetupInterface;

        return podcastSetup.spotifyUrl || null;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next api', 'getPodcastSetupByUid', err);
        captureException(new Error(message, cause));
        return null;
    }
};

export const getAndSetSpotifyUrl = async (user: User | null, setSpotifyUrl: Dispatch<SetStateAction<PodcastPlatformUrl | undefined>>) => {
    if (user?.uid) {
        const newSpotifyUrl = await getSpotifyUrlbyUid(user.uid);
        setSpotifyUrl(newSpotifyUrl);
    }
};

export const convertPodcastChannelToPodcastForm = (podcastChannel: PodcastChannelInterface): PodcastRssFormInterface => {
    const existingFormState = { ...podcastChannel } as PodcastRssFormInterface & Partial<PodcastChannelInterface>;
    delete existingFormState.id;
    delete existingFormState.projectId;
    delete existingFormState.version;
    delete existingFormState.creationDate;
    delete existingFormState.modifiedDate;

    return existingFormState;
};

export const getPodcastRSSFeedUrl = (podcastChannelId: FirestoreDocumentIdType) => {
    return `https://${process.env.B2B_PODCAST_HOST_RSS_FEED}/${podcastChannelId}/rss.xml`;
};
