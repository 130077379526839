import { defineStyleConfig } from '@chakra-ui/react';

export const textareaTheme = defineStyleConfig({
    variants: {
        outline: {
            bgColor: 'brand.darkComponentBg',
            borderColor: 'brand.darkComponentOutline',
            _placeholder: { color: 'gray.text' },
            _focus: {
                borderColor: 'brand.500',
                boxShadow: 'smPrimaryGreen'
            }
        }
    }
});
