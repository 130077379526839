import * as Sentry from '@sentry/nextjs';
import { User } from 'firebase/auth';

export const initSentryUser = (user: User) => {
    const email = user.email as string;
    // asign sentry object as just email at first because
    // even with an email, if displayName and uid are undefined then sentry will call the entire user undefined
    const sentryObj: Sentry.User = { email };
    if (user.displayName) {
        sentryObj.username = user.displayName;
    }
    if (user.uid) {
        sentryObj.id = user.uid;
    }
    Sentry.setUser(sentryObj);
};
