import React from 'react';
import { useColorModeValue, Flex, Button, useBreakpointValue } from '@chakra-ui/react';
import LinkedLogo from '../Logo/LinkedLogo/LinkedLogo';
import { NAVBAR_HEIGHT } from '../../utils/constants/stylingConstants';
import { openNewTab } from '../../helpers/tabHelpers';
import DarkModeLogo from '../Logo/DarkModeLogo/DarkModeLogo';

interface NavBarProps {
    showProductDemoButton?: boolean;
}

export default function NavBar({ showProductDemoButton = false }: NavBarProps) {
    const handleProductDemoLinkClick = () => {
        openNewTab('https://calendly.com/tina-haertel/productdemo?hide_gdpr_banner=1');
    };

    return (
        <Flex w="full" bgColor={'black'} px={{ base: 2, sm: 4 }} borderRadius="md" height={NAVBAR_HEIGHT} position={'fixed'} zIndex={99}>
            <Flex alignItems="center" justifyContent="space-between" w={'full'}>
                <Flex>{<LinkedLogo icon={<DarkModeLogo />} href={process.env.NEXT_PUBLIC_SPLASH_PAGE_BASE_URL as string} />}</Flex>
                <Flex gap={{ base: 1, sm: 4, md: 16 }}>
                    <Flex gap={2}>
                        <Button
                            onClick={handleProductDemoLinkClick}
                            variant={'solid'}
                            display={useBreakpointValue({ base: 'none', md: 'flex' })}
                            visibility={showProductDemoButton ? 'visible' : 'hidden'}
                        >
                            Book Product Demo
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
