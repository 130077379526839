import { User } from 'firebase/auth';
import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { AuthContextInterface, GroupInterface, UserGroupInterface } from '../types/types';
import { EVENT_NAME, USER_PROPERTY_NAME } from '../utils/constants/postHogConstants';
import { registerPosthogEvent } from './postHogEventHelpers';
import { formatSentryError } from './telemetryHelpers';

export const getGroupIdByUid = async (user: User | null) => {
    if (user?.uid) {
        try {
            const response = await axios.get(`/api/v2/groups/getUserGroup/${user.uid}`);
            const userGroup = response.data as UserGroupInterface;
            return userGroup.groupId;
        } catch (err) {
            const { message, cause } = formatSentryError('error hitting /api/v2/groups/getUserGroup/', 'getGroupIdByUid', err);
            captureException(new Error(message, cause));
            return null;
        }
    } else return null;
};

// ! this will need to be updated once a user can be part of multiple groups
export const getGroupByUid = async (user: User | null, attemptCount: number) => {
    if (user?.uid) {
        try {
            const response = await axios.get(`/api/v2/groups/get/users/${user.uid}`);
            const groups = response.data as GroupInterface[];

            if (!groups.length) {
                throw new Error('No groups returned');
            }

            const firstGroup = groups[0];
            return firstGroup;
        } catch (err) {
            // * if after 3 attempts we still can't get the group, we'll start logging to Sentry as it could indicate a larger issue
            if (attemptCount > 3) {
                const { message, cause } = formatSentryError('error hitting /api/v2/groups/users/', 'getGroupIdByUid', err);
                captureException(new Error(message, cause));
            }
            return null;
        }
    } else return null;
};

export const patchGroup = async (groupId: string, groupPayload: Partial<GroupInterface>) => {
    try {
        const response = await axios.patch(`/api/v2/groups/patch/${groupId}`, groupPayload);
        return response.data as GroupInterface;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API groups/patch/', 'patchGroup', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const patchGroupSiteUrl = async (
    group: GroupInterface | null,
    siteUrl: string,
    fetchAndUpdateGroup: AuthContextInterface['fetchAndUpdateGroup']
) => {
    try {
        if (!group?.id) {
            throw new Error('Group ID not found in OnboardWelcomeView');
        }
        const updatedGroup = {
            ...group,
            siteUrl: siteUrl
        };

        // * patch the document
        await patchGroup(group.id, updatedGroup);

        // * refresh local state
        fetchAndUpdateGroup();

        // * updated the user property in posthog
        registerPosthogEvent(EVENT_NAME.set, {
            $set: {
                [USER_PROPERTY_NAME.siteUrl]: siteUrl
            }
        });
    } catch (error) {
        const { message, cause } = formatSentryError('Error patching group withSiteUrl', 'patchGroupWithSiteUrl', error);
        captureException(new Error(message, cause));
        throw error;
    }
};
