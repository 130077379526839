import {
    CmsIntegrationSettingsMethodNameEnum,
    CmsIntegrationSettings,
    HubspotIntegrationSettings,
    NarrationSettingsInterface,
    RSSIntegrationSettings
} from '../../types/types';

export const DEFAULT_NARRATION_SETTINGS: Partial<NarrationSettingsInterface> = {
    name: 'Default', // * should be Group if attached to a group doc, or Integration if attached to a CMSIntegrationSettings doc
    intro: null,
    outro: null,
    wordList: null,
    bodyVoice: '',
    titleVoice: '',
    language: 'en',
    breakTimes: {
        title: null,
        paragraph: null,
        quote: null,
        outro: null
    },
    readQuoteMarks: true
};

export const DEFAULT_CMS_INTEGRATION_SETTINGS = {
    isEnabled: true,
    name: 'Integration'
};

export const DEFAULT_RSS_INTEGRATION_SETTINGS: Partial<RSSIntegrationSettings> = {
    ...DEFAULT_CMS_INTEGRATION_SETTINGS,
    method: CmsIntegrationSettingsMethodNameEnum.RSS
};

export const DEFAULT_HUBSPOT_INTEGRATION_SETTINGS: Partial<HubspotIntegrationSettings> = {
    ...DEFAULT_CMS_INTEGRATION_SETTINGS,
    method: CmsIntegrationSettingsMethodNameEnum.Hubspot
};
