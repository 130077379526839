import {
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    Button,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useFeatureFlagsContext } from '../../../context/hooks/FeatureFlagsHook';
import { useGlobalNarrationContext } from '../../../context/hooks/GlobalNarrationHook';
import { allowResumeTutorial } from '../../../helpers/featureFlagHelpers';
import { ALL_ROUTES } from '../../../types/types';
import { EVENT_NAME } from '../../../utils/constants/postHogConstants';

interface ContinueTutorialModalProps {
    triggerOnOpen?: boolean;
    setTriggerOnOpen?: Dispatch<SetStateAction<boolean>>;
}

export const ContinueTutorialModal = ({ triggerOnOpen, setTriggerOnOpen }: ContinueTutorialModalProps) => {
    const { setIsNewUser, registerPosthogEventWithAuthProps, rssIntegrationSettings, hubspotIntegrationSettings } = useAuth();
    const { isFeaturePaywalled, hasSkippedTutorial, setHasSkippedTutorial } = useFeatureFlagsContext();
    const { successNarrations, successAndDraftNarrations } = useGlobalNarrationContext();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const router = useRouter();
    const toast = useToast();
    const continueButtonRef = useRef<HTMLButtonElement>(null);

    // * manually trigger the modal to open from the parent component
    useEffect(() => {
        if (triggerOnOpen && !!setTriggerOnOpen) {
            // * Reset the trigger boolean so that it can be used again
            setTriggerOnOpen(false);
            registerPosthogEventWithAuthProps(EVENT_NAME.tutorialModal_clickOpen, {
                hasDraftNarration: !!successAndDraftNarrations?.length
            });
            onOpen();
        }
        // ignore warning missing dependency. Resolving results in unnecessary re-renders
    }, [triggerOnOpen]); // eslint-disable-line

    useEffect(() => {
        /*
         * If the user has already decided to skip tutorial in this modal, do not show the modal again
         * If the user has already created a narration, do not auto show the modal. It could still be opened manually if the user only has a draft.
           The point above is to prevent this bug: https://linear.app/ad-auris/issue/ENG-1391/bug-tutorial-modal-appears-if-all-of-the-users-narrations-are-drafts
         * See allowResumeTutorial for rest of logic
         */
        if (successAndDraftNarrations === undefined || successAndDraftNarrations.length) return;
        // * Do not show the modal on the settings page since it could deter users from paying
        if (!router.pathname || router.pathname === ALL_ROUTES.SETTINGS) return;

        const tutorialIsAvailable = allowResumeTutorial(isFeaturePaywalled, successNarrations, rssIntegrationSettings, hubspotIntegrationSettings);
        if (!hasSkippedTutorial && tutorialIsAvailable) {
            registerPosthogEventWithAuthProps(EVENT_NAME.tutorialModal_openOnLoad, {
                hasDraftNarration: !!successAndDraftNarrations?.length
            });
            setHasSkippedTutorial(true);
            onOpen();
        }
        // ignore warning missing dependency. Resolving results in unnecessary re-renders
    }, [
        isFeaturePaywalled,
        successNarrations,
        onOpen,
        hasSkippedTutorial,
        setHasSkippedTutorial,
        successAndDraftNarrations,
        router,
        rssIntegrationSettings,
        hubspotIntegrationSettings
    ]); // eslint-disable-line

    const handleContinueTutorial = () => {
        setIsNewUser(true);
        let narrationId;
        let route = ALL_ROUTES.HOME;
        if (successAndDraftNarrations?.length) {
            route = ALL_ROUTES.AUDIO_STUDIO_VERBATIM;
            narrationId = successAndDraftNarrations[0].id;
        }
        registerPosthogEventWithAuthProps(EVENT_NAME.tutorialModal_clickContinue, {
            hasDraftNarration: !!successAndDraftNarrations?.length,
            narrationId,
            route
        });

        router.push({ pathname: route, query: narrationId ? { narration_id: narrationId } : undefined });
        toast({
            title: 'Welcome back to the Adauris tutorial!',
            description: narrationId ? 'Continue creating your first episode.' : 'Start by creating your first episode.',
            status: 'info',
            position: 'top',
            duration: 5000,
            isClosable: true
        });
        onClose();
    };

    const handleSkipTutorial = () => {
        registerPosthogEventWithAuthProps(EVENT_NAME.tutorialModal_clickSkip, {
            hasDraftNarration: !!successAndDraftNarrations?.length
        });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={continueButtonRef}>
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent>
                <ModalHeader>Would you like to continue where you left off in the tutorial?</ModalHeader>
                <ModalCloseButton />

                <ModalFooter>
                    <HStack>
                        <Button variant="outline" onClick={handleSkipTutorial}>
                            Maybe Later
                        </Button>
                        <Button mr={3} onClick={handleContinueTutorial} ref={continueButtonRef}>
                            Continue Tutorial
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
