import { CATEGORY_TYPE, PodcastMetadataInterface } from '../../types/types';

export const DEFAULT_DONE_BUTTON_TEXT = 'I successfully followed my podcast';
export const ALT_DONE_BUTTON_TEXT = 'I successfully submitted my podcast';

export const SPOTIFY_PODCASTS_NAME = 'Spotify';
export const APPLE_PODCASTS_NAME = 'Apple Podcasts';
export const GOOGLE_PODCASTS_NAME = 'Google Podcasts';
export const RSS_PODCASTS_NAME = 'Other by RSS Feed';

export const SPOTIFY_SUBMIT_LINK = 'https://podcasters.spotify.com/dash/submit';
export const SPOTIFY_GUIDE_LINK = 'https://help.spotifyforpodcasters.com/hc/en-us/articles/12625829801627';
export const GOOGLE_GUIDE_LINK = 'https://support.google.com/podcast-publishers/answer/9479755?hl=en&sjid=8631786014131057549-NA#zippy=%2Cadd-a-show';
export const APPLE_GUIDE_LINK = 'https://podcasters.apple.com/support/897-submit-a-show';
export const YOUTUBE_GUIDE_LINK = 'https://support.google.com/youtube/answer/13973017?sjid=8974009620718067808-NC';

export const OVERCAST_LANDING_PAGE = 'https://overcast.fm';
export const APPLE_PODCASTS_LANDING_PAGE = 'https://www.apple.com/apple-podcasts/';
export const SPOTIFY_PODCASTS_LANDING_PAGE = 'https://podcasters.spotify.com/';

export const SPOTIFY_PODCASTS_METADATA: PodcastMetadataInterface = {
    name: SPOTIFY_PODCASTS_NAME,
    image: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/spotify.jpg',
    link: '/podcasts/spotify',
    listenOnImage: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/listen-on-spotify.svg',
    icon: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/spotify-icon-comp.png',
    color: '#1DB954',
    tutorialVideoSrc: 'https://www.loom.com/embed/2fde7e2f267a4f1c8a612af851f5d1f8',
    tutorialVideoSrcMobile: 'https://www.loom.com/embed/72a05a915d3d43d89d87584e99d6070c',
    doneButtonText: ALT_DONE_BUTTON_TEXT,
    isUnderMaintenance: false
};

export const APPLE_PODCASTS_METADATA: PodcastMetadataInterface = {
    name: APPLE_PODCASTS_NAME,
    image: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/apple-podcasts-scaled.jpg',
    link: '/podcasts/apple',
    listenOnImage: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/listen-on-apple.svg',
    icon: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/apple-podcasts-icon-comp.png',
    color: '#aa1dd3',
    tutorialVideoSrc: 'https://www.loom.com/embed/5446952f73b843e19faf321cae5d8928',
    tutorialVideoSrcMobile: 'https://www.loom.com/embed/51864215b1e74176a90a4d08afda6e5c',
    doneButtonText: DEFAULT_DONE_BUTTON_TEXT,
    isUnderMaintenance: false
};

export const GOOGLE_PODCASTS_METADATA: PodcastMetadataInterface = {
    name: GOOGLE_PODCASTS_NAME,
    image: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/google-podcasts-banner.jpg',
    link: '/podcasts/google',
    listenOnImage: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/listen-on-google.svg',
    icon: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/google-podcasts-icon-comp.png',
    color: '#ea4335',
    tutorialVideoSrc: 'https://www.loom.com/embed/ca20461ae82d456e9ab6e4c9265d3e99',
    tutorialVideoSrcMobile: 'https://www.loom.com/embed/21f40bcb2dd44d4392c56b606d8a8a97',
    doneButtonText: DEFAULT_DONE_BUTTON_TEXT,
    isUnderMaintenance: false
};

export const RSS_PODCASTS_METADATA: PodcastMetadataInterface = {
    name: RSS_PODCASTS_NAME,
    image: '',
    link: '/podcasts/',
    listenOnImage: '',
    icon: 'https://storage.googleapis.com/ad-auris-django-bucket/media/play-app-podcasts-media/rss.png',
    isUnderMaintenance: false
};

export const SUPPORTED_PODCASTS = [SPOTIFY_PODCASTS_METADATA, APPLE_PODCASTS_METADATA, GOOGLE_PODCASTS_METADATA];

// * these values must follow Apple standards https://podcasters.apple.com/support/1691-apple-podcasts-categories
export const PODCAST_SUBCATEGORIES = {
    Arts: ['Books', 'Design', 'Fashion &amp; Beauty', 'Food', 'Performing Arts', 'Visual Arts'],
    Business: ['Careers', 'Entrepreneurship', 'Investing', 'Management', 'Marketing', 'Non-Profit'],
    Comedy: ['Comedy Interviews', 'Improv', 'Stand-Up'],
    Education: ['Courses', 'How To', 'Language Learning', 'Self-Improvement'],
    Fiction: ['Comedy Fiction', 'Drama', 'Science Fiction'],
    Government: [],
    History: [],
    'Health &amp; Fitness': ['Alternative Health', 'Fitness', 'Medicine', 'Mental Health', 'Nutrition', 'Sexuality'],
    'Kids &amp; Family': ['Education for Kids', 'Parenting', 'Pets &amp; Animals', 'Stories for Kids'],
    Leisure: ['Animation &amp; Manga', 'Automotive', 'Aviation', 'Crafts', 'Games', 'Hobbies', 'Home &amp; Garden', 'Video Games'],
    Music: ['Music Commentary', 'Music History', 'Music Interviews'],
    News: ['Business News', 'Daily News', 'Entertainment News', 'News Commentary', 'Politics', 'Sports News', 'Tech News'],
    'Religion &amp; Spirituality': ['Buddhism', 'Christianity', 'Hinduism', 'Islam', 'Judaism', 'Religion', 'Spirituality'],
    Science: ['Astronomy', 'Chemistry', 'Earth Sciences', 'Life Sciences', 'Mathematics', 'Natural Sciences', 'Nature', 'Physics', 'Social Sciences'],
    'Society &amp; Culture': ['Documentary', 'Personal Journals', 'Philosophy', 'Places &amp; Travel', 'Relationships'],
    Sports: [
        'Baseball',
        'Basketball',
        'Cricket',
        'Fantasy Sports',
        'Football',
        'Golf',
        'Hockey',
        'Rugby',
        'Soccer',
        'Swimming',
        'Tennis',
        'Volleyball',
        'Wilderness',
        'Wrestling'
    ],
    Technology: [],
    'True Crime': [],
    'TV &amp; Film': ['After Shows', 'Film History', 'Film Interviews', 'Film Reviews', 'TV Reviews']
};

export const PODCAST_CATEGORIES = Object.keys(PODCAST_SUBCATEGORIES) as unknown as CATEGORY_TYPE[];
