// * This container allows us to access context variables within _appMultiProvider level

import { AppProps } from 'next/app';
import GlobalNarrationProvider from '../../context/hooks/GlobalNarrationHook';
import AppComponentContainer from './_appComponentContainer';

function AppGlobalNarrationProviderContainer(appProps: AppProps) {
    return (
        <GlobalNarrationProvider>
            <AppComponentContainer {...appProps} />
        </GlobalNarrationProvider>
    );
}

export default AppGlobalNarrationProviderContainer;
