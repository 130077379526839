import { defineStyleConfig } from '@chakra-ui/react';

export const inputTheme = defineStyleConfig({
    variants: {
        outline: {
            field: {
                bgColor: 'brand.darkComponentBg',
                borderColor: 'brand.darkComponentOutline',
                _placeholder: { color: 'gray.text' },
                _focus: {
                    borderColor: 'brand.500',
                    boxShadow: 'smPrimaryGreen'
                }
            }
        }
    }
});
