import axios from 'axios';
import { FirestoreDocumentIdType, NarrationInterface, NarrationPostPayloadInterface } from '../types/types';
import { captureException } from '@sentry/nextjs';

export const requestDeletion = async (narrationId: FirestoreDocumentIdType) => {
    try {
        await axios.delete(`/api/v2/narrations/delete-narration/${narrationId}`);
    } catch (err) {
        captureException(new Error('Error requesting deletion - via narrationHelpers', { cause: err as Error }));
        throw err;
    }
};

export const createNarrationDocument = async (
    payload: NarrationPostPayloadInterface,
    mutateNarrations: () => void // * should be passed from globalNarrations swr context
): Promise<FirestoreDocumentIdType> => {
    try {
        const response = await axios.post('/api/v2/narrations/post-narration', payload);
        const narrationId: FirestoreDocumentIdType = response.data;
        // * update local state of narrations
        mutateNarrations();
        return narrationId;
    } catch (error) {
        throw error;
    }
};

export const updateNarrationDocument = async (
    narrationId: FirestoreDocumentIdType,
    payload: Partial<NarrationPostPayloadInterface>,
    mutateNarrations: () => void // * should be passed from globalNarrations swr context
): Promise<FirestoreDocumentIdType> => {
    try {
        const response = await axios.patch(`/api/v2/narrations/patch-narration/${narrationId}`, payload);
        const returnedNarrationId: FirestoreDocumentIdType = response.data;
        mutateNarrations();
        return returnedNarrationId;
    } catch (error) {
        throw error;
    }
};

export const updateNarrationDocumentAndUpdateCredits = async (
    narrationId: FirestoreDocumentIdType,
    payload: Partial<NarrationPostPayloadInterface>,
    mutateNarrations: () => void, // * should be passed from globalNarrations swr context
    fetchAndUpdateGroupAfterCreditUpdate: () => void // * should be passed from auth context
): Promise<FirestoreDocumentIdType> => {
    try {
        const returnedNarrationId = await updateNarrationDocument(narrationId, payload, mutateNarrations);
        fetchAndUpdateGroupAfterCreditUpdate();
        
        return returnedNarrationId;
    } catch (error) {
        throw error;
    }
}

export const getNarrations = async (projectId: FirestoreDocumentIdType, limit?: number, offset?: number): Promise<NarrationInterface[]> => {
    try {
        const response = await axios.get(`/api/v2/narrations/get-narrations/${projectId}?limit=${limit}&offset=${offset}`);
        const data = response.data;
        return data;
    } catch (err) {
        return [];
    }
};

export const getNarration = async (narrationId: FirestoreDocumentIdType): Promise<NarrationInterface> => {
    try {
        const response = await axios.get(`/api/v2/narrations/get-narration/${narrationId}`);
        const data = response.data;
        return data;
    } catch (err) {
        throw err;
    }
};
