import { captureException } from '@sentry/nextjs';
import { formatSentryError } from './telemetryHelpers';

export const registerTwitterEvent = async (eventCode: string, additionalParameters: Record<string, any>) => {
    try {
        // @ts-ignore - twq is a global function installed in MetaHead.tsx
        window.twq('event', eventCode, additionalParameters);
    } catch (e) {
        const { message, cause } = formatSentryError(`Error registering Twitter event code ${eventCode}`, 'registerTwitterEvent', e);
        captureException(new Error(message, cause));
    }
};
