import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import React, { createContext, useContext, ReactNode, FC, useEffect, useState } from 'react';
import { formatSentryError } from '../../helpers/telemetryHelpers';
import { createISO639LanguageArray, formatVoices, getLanguageAccentsMapFromVoices } from '../../helpers/voiceHelpers';
import { LanguageAccentsMapType, SupportedISO639LanguagesType, VoiceDataContextInterface, VoiceInterface } from '../../types/types';
import { INITIAL_LANUAGE_ACCENTS_MAP } from '../../utils/constants/voiceConstants';

const VoiceDataContext = createContext<VoiceDataContextInterface>({
    voices: [],
    languageAccentsMap: INITIAL_LANUAGE_ACCENTS_MAP,
    uniqueLanguages: []
});

export const useVoiceData = () => useContext(VoiceDataContext);

interface VoiceDataProviderProps {
    children: ReactNode;
}

export const VoiceDataProvider: FC<VoiceDataProviderProps> = ({ children }) => {
    const [voices, setVoices] = useState<VoiceInterface[]>([]);
    const [languageAccentsMap, setLanguageAccentsMap] = useState<LanguageAccentsMapType>(INITIAL_LANUAGE_ACCENTS_MAP);
    const [uniqueLanguages, setUniqueLanguages] = useState<SupportedISO639LanguagesType[]>([]);

    useEffect(() => {
        const fetchVoices = async () => {
            try {
                const response = await axios.get('/api/v2/audio-voices');
                const { voices: fetchedVoices } = response.data;

                const formattedVoices = formatVoices(fetchedVoices);
                setVoices(formattedVoices);

                const _uniqueLanguages = createISO639LanguageArray(formattedVoices) as SupportedISO639LanguagesType[];
                setUniqueLanguages(_uniqueLanguages);

                const _languageAccentsMap = getLanguageAccentsMapFromVoices(formattedVoices);
                setLanguageAccentsMap(_languageAccentsMap);
            } catch (error) {
                const { message, cause } = formatSentryError('Error fetching voices', 'VoiceDataHook', error);
                captureException(new Error(message, cause));
            }
        };

        // * we only need to fetch voices once since its static data
        if (!voices.length) {
            fetchVoices();
        }
    }, [voices]);

    return <VoiceDataContext.Provider value={{ voices, languageAccentsMap, uniqueLanguages }}>{children}</VoiceDataContext.Provider>;
};
