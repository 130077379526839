import { User } from 'firebase/auth';

// docs here: https://developers.canny.io/install
// script loads in MetaHead.tsx
export const initCannyUser = (user: User) => {
    if ('Canny' in window) {
        const cannyObj: Record<string, any> = {
            appID: process.env.NEXT_PUBLIC_CANNY_APP_ID,
            user: {
                // * These values are required
                email: user.email,
                id: user.uid,
                name: user.displayName || user.email
            }
        };

        // * Optional: Add more user properties if they are available
        if (user.metadata.creationTime) {
            cannyObj.user.created = new Date(user.metadata.creationTime);
        }
        if (user.photoURL) {
            cannyObj.user.avatarURL = user.photoURL;
        }

        // @ts-ignore - Canny is a global function installed in MetaHead.tsx
        window.Canny('identify', cannyObj);
    } else {
        throw new Error('Canny not loaded in window');
    }
};
