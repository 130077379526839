import { Interpolation, SelectProps, StackProps } from '@chakra-ui/react';

// use for calculating spacing on pages that import the navbar
export const NAVBAR_HEIGHT = '56px';
export const SIDEBAR_WIDTH = '60';
export const FULL_WIDTH_MINUS_SIDEBAR = `calc(100vw - var(--chakra-sizes-${SIDEBAR_WIDTH}))`;
export const FULL_HEIGHT_MINUS_NAVBAR = `calc(100vh - ${NAVBAR_HEIGHT})`;
export const SELECT_PROPS: SelectProps = {
    width: '30%',
    minWidth: 'fit-content'
};
export const FORM_VSTACK_PROPS: StackProps = {
    alignItems: 'flex-start',
    spacing: 0,
    width: '100%'
};

// * taken from b2b-splash-page app for narration sharing components
export const DEFAULT_MAX_WIDTH = '1200px';

export const SCROLLBAR_ALWAYS_VISIBLE_WHEN_OVERFLOW_CSS = {
    '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        scrollbarWidth: 'thin'
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey',
        borderRadius: '10px'
    }
} as Interpolation<{}>;
