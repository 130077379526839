import React, { ReactNode } from 'react';
import { Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ALL_ROUTES } from '../../../types/types';

interface LinkedLogoProps {
    icon: ReactNode;
    href: string;
}

const LinkedLogo = ({ icon, href }: LinkedLogoProps) => {
    const router = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        router.push(href);
    };

    return (
        <Link onClick={handleClick} data-testid={'linked-logo'}>
            {icon}
        </Link>
    );
};

export default LinkedLogo;
