import { Card, CardProps } from '@chakra-ui/react';

// * using & any so that we can pass any props to the card when using with as prop. Ie. as={motion.button}
export const DarkCard = (cardProps: CardProps & any) => {
    return (
        <Card bg={'brand.darkComponentBg'} border="1px solid" borderColor={'brand.darkComponentOutline'} {...cardProps}>
            {cardProps.children}
        </Card>
    );
};
