import { Icon } from '@chakra-ui/react';
const DarkModeLogo = (props) => (
    <Icon {...props} width="142" height="27" viewBox="0 0 142 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="paint0_linear_1_54" x1="10.95" y1="-15.6774" x2="10.95" y2="73.8281" gradientUnits="userSpaceOnUse">
                <stop offset="0.300328" stopColor="#37E073" />
                <stop offset="0.562493" stopColor="#BCFE3B" />
            </linearGradient>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <path
                d="M49.1321 22.7394V4.32512H55.6678C57.6559 4.32512 59.3118 4.73583 60.6356 5.55726C61.9339 6.35122 62.9736 7.50508 63.6285 8.87869C64.3178 10.3282 64.6649 11.9167 64.6428 13.5215C64.6837 15.2128 64.3046 16.8878 63.5392 18.3966C62.8337 19.7501 61.7473 20.8674 60.4142 21.6108C58.9582 22.3972 57.3221 22.7899 55.6678 22.7501L49.1321 22.7394ZM61.6856 13.5073C61.7102 12.3274 61.4659 11.1574 60.9714 10.0858C60.5226 9.12912 59.8118 8.31924 58.9214 7.75012C57.9431 7.15448 56.8125 6.85664 55.6678 6.89298H52.0642V20.1751H55.6678C56.825 20.2121 57.9672 19.9051 58.9499 19.293C59.8385 18.704 60.5438 17.8774 60.9857 16.9073C61.4649 15.8388 61.7039 14.6782 61.6856 13.5073Z"
                fill="white"
            />
            <path
                d="M92.0213 22.8715C90.7392 22.9118 89.4653 22.6538 88.2999 22.118C87.327 21.6511 86.488 20.9459 85.8606 20.068C85.2344 19.1744 84.778 18.1732 84.5142 17.1144C84.226 15.9872 84.0819 14.8279 84.0856 13.6644V4.32512H87.0142V13.6644C87.0117 14.4874 87.0943 15.3084 87.2606 16.1144C87.4103 16.8608 87.6869 17.5759 88.0784 18.2287C88.4535 18.8451 88.9812 19.3543 89.6106 19.7073C90.3455 20.0916 91.1675 20.2786 91.9963 20.2501C92.8414 20.2821 93.6805 20.0951 94.432 19.7073C95.0684 19.3477 95.6011 18.8297 95.9784 18.2037C96.3739 17.5414 96.654 16.8168 96.807 16.0608C96.9728 15.268 97.0554 14.4601 97.0534 13.6501V4.32512H99.9856V13.6644C99.9932 14.8699 99.8394 16.0711 99.5284 17.2358C99.2535 18.2981 98.7784 19.2982 98.1284 20.1823C97.4865 21.0395 96.639 21.7212 95.6642 22.1644C94.5167 22.6653 93.2728 22.9068 92.0213 22.8715Z"
                fill="white"
            />
            <path
                d="M103.85 22.7396V4.32528H111.853C112.649 4.31891 113.435 4.50128 114.146 4.85742C114.823 5.198 115.429 5.66331 115.932 6.22885C116.442 6.79772 116.846 7.4533 117.125 8.16457C117.405 8.85193 117.55 9.58665 117.553 10.3289C117.558 11.1319 117.409 11.9284 117.114 12.6753C116.832 13.3911 116.403 14.0405 115.857 14.5824C115.319 15.1128 114.667 15.5124 113.95 15.7503L118.282 22.7396H115.014L111.032 16.336H106.778V22.7396H103.85ZM106.778 13.786H111.853C112.371 13.7926 112.875 13.6225 113.282 13.3039C113.708 12.9707 114.046 12.5393 114.268 12.0467C114.51 11.5094 114.632 10.9255 114.625 10.336C114.631 9.75393 114.496 9.1791 114.231 8.66047C113.967 8.14183 113.581 7.69479 113.107 7.35742C112.69 7.06071 112.19 6.90209 111.678 6.90385H106.778V13.786Z"
                fill="white"
            />
            <path d="M120.993 22.7394V4.32512H123.921V22.7394H120.993Z" fill="white" />
            <path
                d="M138.939 8.65721C138.725 8.41714 138.476 8.21119 138.2 8.04649C137.829 7.81355 137.438 7.61396 137.032 7.45007C136.561 7.26035 136.076 7.10518 135.582 6.98578C135.052 6.85393 134.507 6.78795 133.961 6.78935C132.751 6.78935 131.852 7.01316 131.264 7.46078C130.977 7.67565 130.746 7.95748 130.592 8.28176C130.439 8.60604 130.366 8.96296 130.382 9.32149C130.356 9.83263 130.533 10.3333 130.875 10.7144C131.302 11.1161 131.822 11.4047 132.389 11.5536C133.075 11.7679 133.919 11.9953 134.921 12.2358C136.039 12.4845 137.128 12.8434 138.175 13.3072C139.004 13.6574 139.728 14.2175 140.275 14.9322C140.803 15.7236 141.062 16.6641 141.014 17.6144C141.034 18.4377 140.852 19.2533 140.482 19.9894C140.138 20.6493 139.635 21.2128 139.018 21.6286C138.364 22.0647 137.638 22.3837 136.875 22.5715C136.019 22.784 135.139 22.8884 134.257 22.8822C133.337 22.8819 132.42 22.7813 131.521 22.5822C130.628 22.3907 129.758 22.1046 128.925 21.7286C128.112 21.3611 127.343 20.9035 126.632 20.3644L127.979 17.8644C128.265 18.1513 128.588 18.3998 128.939 18.6036C129.414 18.8959 129.911 19.1514 130.425 19.3679C131.02 19.6221 131.635 19.8263 132.264 19.9786C132.93 20.1432 133.614 20.2259 134.3 20.2251C135.462 20.2251 136.349 20.0239 136.961 19.6215C137.255 19.4349 137.495 19.1738 137.656 18.8646C137.818 18.5554 137.894 18.2091 137.879 17.8608C137.889 17.5879 137.84 17.3161 137.735 17.064C137.63 16.8118 137.472 16.5853 137.271 16.4001C136.76 15.9739 136.168 15.6534 135.532 15.4572C134.782 15.2001 133.879 14.9417 132.821 14.6822C131.777 14.4272 130.762 14.0682 129.789 13.6108C129.059 13.2839 128.431 12.7656 127.971 12.1108C127.546 11.4031 127.338 10.5857 127.375 9.76078C127.34 8.67475 127.645 7.60487 128.246 6.70007C128.842 5.84957 129.676 5.19366 130.643 4.81435C131.723 4.38156 132.879 4.16672 134.043 4.18221C134.841 4.17819 135.637 4.26931 136.414 4.45364C137.138 4.63248 137.841 4.88747 138.511 5.21435C139.124 5.50562 139.703 5.86503 140.236 6.28578L138.939 8.65721Z"
                fill="white"
            />
            <path
                d="M39.6892 4.32512H36.9428L29.7571 22.743H32.8178L34.6249 17.7358L35.4035 15.5823L38.3178 7.51798L41.2178 15.5823H36.4535L35.6821 17.7358H41.9892L43.7892 22.743H46.8499L39.6892 4.32512Z"
                fill="white"
            />
            <path
                d="M75.6606 4.32512H72.9141L65.7284 22.743H68.7891L70.5963 17.7358L71.3748 15.5823L74.2855 7.51798L77.1855 15.5823H72.4248L71.6498 17.7358H77.9606L79.7606 22.743H82.8213L75.6606 4.32512Z"
                fill="white"
            />
            <path
                d="M4.77857 0H7.56786V13.4786H4.77857V0ZM0 13.4786H2.775V4.43929H0V13.4786ZM17.1143 13.4786H14.3536V22.5179H17.1321L17.1143 13.4786ZM12.3357 7.05H9.55714V26.9357H12.3357V7.06786V7.05ZM19.1214 13.4786V19.9071H21.9V13.4786H19.1214ZM15.7429 24.1429C15.4603 24.1429 15.1841 24.2266 14.9492 24.3836C14.7143 24.5406 14.5312 24.7637 14.423 25.0247C14.3149 25.2858 14.2866 25.573 14.3417 25.8501C14.3969 26.1272 14.5329 26.3818 14.7327 26.5816C14.9325 26.7814 15.187 26.9174 15.4642 26.9726C15.7413 27.0277 16.0285 26.9994 16.2895 26.8913C16.5506 26.7831 16.7737 26.6 16.9307 26.3651C17.0876 26.1302 17.1714 25.854 17.1714 25.5714C17.1714 25.1925 17.0209 24.8292 16.753 24.5613C16.4851 24.2934 16.1217 24.1429 15.7429 24.1429Z"
                fill="url(#paint0_linear_1_54)"
            />
        </g>
    </Icon>
);

export default DarkModeLogo;
