import { User } from 'firebase/auth';
import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { FirestoreDocumentIdType, GroupInterface, NarrationBlockInterface, ProjectInterface, ThumbFeedbackEnum } from '../types/types';
import { APP_ENV } from '../utils/constants/constants';
const defaultWebHook = process.env.DEFAULT_SLACK_WEBHOOK;
const rssWebhook = process.env.RSS_VALIDATION_SLACK_WEBHOOK;
const winsChannelWebhook = process.env.WINS_CHANNEL_SLACK_WEBHOOK;
const audioEditRequestsWebhook = process.env.SLACK_AUDIO_EDIT_REQUESTS_WEBHOOK;
const firstUseDataReportWebhook = process.env.SLACK_FIRST_USE_DATA_REPORT_WEBHOOK;

const defaultAuthorIcon =
    'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80';
//hooks.slack.com/services/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

export const sendNotificationToAda = async (log: string, error: boolean) => {
    try {
        const slackbody = {
            mkdwn: true,
            attachments: [
                {
                    pretext: 'B2B Dashboard Notification',
                    author_name: `Environment: ${process.env.APP_ENV}`,
                    author_icon: defaultAuthorIcon,
                    title: error ? 'Error Notification' : 'Activity Log',
                    color: error ? '#f50057' : 'good',
                    text: log
                }
            ]
        };

        await axios.post(`https://hooks.slack.com/services/${defaultWebHook}`, slackbody, {
            // fixes CORS issue
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    } catch (err: unknown) {
        captureException(new Error('Ada slackbot error - via slackHelpers', { cause: err as Error }));
    }
};

export const alertRssValidationToAda = async (validationSuccess: boolean, rssFeedUrl: string, user: User | null) => {
    // * only log for production so that slack isn't flooded
    if (process.env.APP_ENV === APP_ENV.production) {
        try {
            const slackbody = {
                mkdwn: true,
                attachments: [
                    {
                        pretext: `B2B Dashboard RSS Notification - Environment: ${process.env.APP_ENV}`,
                        author_name: `${user?.displayName} | ${user?.email} | ${user?.uid}`,
                        author_icon: defaultAuthorIcon,
                        title: `RSS Validation Request`,
                        color: validationSuccess ? 'good' : '#f50057',
                        text: `RSS Feed validation ${validationSuccess ? 'succeeded' : 'failed'} for ${rssFeedUrl}`
                    }
                ]
            };

            await axios.post(`https://hooks.slack.com/services/${rssWebhook}`, slackbody, {
                // fixes CORS issue
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        } catch (err: unknown) {
            captureException(new Error('Ada rss validation slackbot error - via slackHelpers', { cause: err as Error }));
        }
    }
};

export const alertSignupToAda = async (user: User) => {
    // * only log for production so that slack isn't flooded
    if (process.env.APP_ENV === APP_ENV.production) {
        try {
            const slackbody = {
                mkdwn: true,
                attachments: [
                    {
                        pretext: `Environment: ${process.env.APP_ENV}`,
                        title: `New B2B Dashboard User! 🎉`,
                        color: 'good',
                        text: `${user.displayName} | ${user.email} | ${user.uid}`
                    }
                ]
            };

            await axios.post(`https://hooks.slack.com/services/${winsChannelWebhook}`, slackbody, {
                // fixes CORS issue
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        } catch (err: unknown) {
            captureException(new Error('Ada alert signup slackbot error - via slackHelpers', { cause: err as Error }));
        }
    }
};

export const alertAudioEditRequest = async (
    user: User,
    audioUrl: string,
    blocks: NarrationBlockInterface[],
    feedback: string,
    rating: ThumbFeedbackEnum | null
) => {
    // * only log for production so that slack isn't flooded
    if (process.env.APP_ENV === APP_ENV.production) {
        try {
            const blocksObjectsAsStrings = blocks.map((block) => {
                return JSON.stringify(block);
            });
            const blocksString = blocksObjectsAsStrings.join('\n');
            const slackbody = {
                mkdwn: true,
                attachments: [
                    {
                        pretext: `Environment: ${process.env.APP_ENV}`,
                        author_name: `${user.displayName} | ${user.email} | ${user.uid}`,
                        title: `Audio Edit Request`,
                        color: 'good',
                        text: `User's Feedback: ${feedback} \n\n User's Rating: ${rating} \n\n Audio Url: ${audioUrl} \n\n Their blocks input: ${blocksString}`
                    }
                ]
            };

            await axios.post(`https://hooks.slack.com/services/${audioEditRequestsWebhook}`, slackbody, {
                // fixes CORS issue
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        } catch (err: unknown) {
            captureException(new Error('Ada alertAudioEditRequest slackbot error - via slackHelpers', { cause: err as Error }));
        }
    }
};

export const sendRequestForDataReport = async (
    user: User,
    project: ProjectInterface,
    group: GroupInterface,
    narrationDocumentId: FirestoreDocumentIdType,
    audioUrl: string,
    title: string
) => {
    // * only log for production so that slack isn't flooded
    if (process.env.APP_ENV === APP_ENV.production) {
        try {
            const slackbody = {
                mkdwn: true,
                attachments: [
                    {
                        pretext: `Environment: ${process.env.APP_ENV}`,
                        author_name: `${user.displayName} | ${user.email} | ${user.uid}`,
                        title: `Data Report Request`,
                        color: 'good',
                        text: `User has requested a data report for the narration with title=${title}, narrationDocumentId=${narrationDocumentId} and audioUrl=${audioUrl}. The user has projectId=${project.id} and groupId=${group.id}`
                    }
                ]
            };

            await axios.post(`https://hooks.slack.com/services/${firstUseDataReportWebhook}`, slackbody, {
                // fixes CORS issue
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        } catch (err: unknown) {
            captureException(new Error('Ada sendRequestForDataReport slackbot error - via slackHelpers', { cause: err as Error }));
        }
    }
};
