import { Container, Stack, Button, Divider, ButtonGroup, IconButton, Text } from '@chakra-ui/react';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { linkToNewUrl } from '../../helpers/tabHelpers';

export const ShareNarrationFooter = () => {
    const handleBlogClick = () => {
        linkToNewUrl('https://welcome.adauris.ai/blog');
    };
    const handleAboutUsClick = () => {
        linkToNewUrl('https://welcome.adauris.ai/who-we-are');
    };

    const handleContactUsClick = () => {
        linkToNewUrl('https://welcome.adauris.ai/contact-us');
    };

    return (
        <Container as="footer" role="contentinfo" maxW={'100%'}>
            <Divider />
            <Stack
                pt="8"
                pb="12"
                justify="space-between"
                direction={{ base: 'column-reverse', md: 'row' }}
                align={{ base: 'center', md: 'flex-end' }}
            >
                <Text fontSize="sm" color="subtle">
                    &copy; {new Date().getFullYear()} Ad Auris, Inc. All rights reserved.
                </Text>

                <ButtonGroup variant="ghost">
                    <IconButton
                        as="a"
                        href="https://www.linkedin.com/company/ad-auris"
                        aria-label="LinkedIn"
                        icon={<FaLinkedin fontSize="1.25rem" />}
                    />
                    <IconButton as="a" href="https://twitter.com/AdAuris_audio" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
                </ButtonGroup>
                <Stack
                    textAlign={{ base: 'center', md: 'start' }}
                    direction={{ base: 'column-reverse', md: 'column', lg: 'row' }}
                    spacing={{ base: '12', md: '8' }}
                >
                    <Stack spacing="3" shouldWrapChildren minW="36" flex="1" direction={'row'}>
                        <Button variant="link" onClick={handleContactUsClick}>
                            Contact Us
                        </Button>
                        <Button variant="link" onClick={handleAboutUsClick}>
                            About Us
                        </Button>
                        <Button variant="link" onClick={handleBlogClick}>
                            Blog
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
};
