import { captureException } from '@sentry/nextjs';

// opens new tab with given URL
export const openNewTab = (url: string) => {
    try {
        window.open(url, '_blank', 'noopener,noreferrer');
    } catch (e) {
        console.log(e);
        console.log(`couldn't open tab ${url}`);
    }
};

export const linkToNewUrl = (url: string) => {
    try {
        window.location.href = url;
    } catch (e) {
        captureException(e);
    }
};
