import { NarrationMethodEnum, NarrationTypeEnum } from '../../types/types';

export const TEXT_NARRATION_DEFAULTS = {
    narrationType: NarrationTypeEnum.Text,
    narrationMethod: NarrationMethodEnum.SingleNarrationRequest,
    businessDomain: 'B2B',
    version: '4.0.0'
};

export const BLOCKS_NARRATION_DEFAULTS = {
    businessDomain: 'B2B',
    version: '4.0.0'
};
