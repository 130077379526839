import axios from 'axios';
import {
    VoiceInterface,
    ISO639LanguageCode,
    tierMapping,
    Tier,
    LanguageDropedownValueType,
    LanguageAccentsMapType,
    SupportedISO639LanguagesType,
    AccentEnum
} from '../types/types';
import { formatSentryError, formatSentryMessage } from './telemetryHelpers';
import { MULTIPLE_LANGUAGES_OPTION_NAME } from '../utils/constants/constants';
import { captureException } from '@sentry/nextjs';
import { INITIAL_LANUAGE_ACCENTS_MAP } from '../utils/constants/voiceConstants';

export async function getVoices() {
    try {
        const voices = await retrieveVoices();
        if (!Array.isArray(voices)) {
            throw new Error('Expected an array of voices');
        }
        return voices;
    } catch (error) {
        const { message, cause } = formatSentryError('Expected an array of voices', 'getVoices', error);
        captureException(new Error(message, cause));
        return [];
    }
}

export const createISO639LanguageArray = (voices: VoiceInterface[]): ISO639LanguageCode[] => {
    try {
        return Array.from(
            new Set(
                (voices ?? []).flatMap((voice) => {
                    if (voice && voice.language) {
                        return voice.language;
                    } else {
                        console.error('Invalid voice object encountered', voice);
                        return [];
                    }
                })
            )
        );
    } catch (error) {
        const { message, cause } = formatSentryError('Error processing voices to create ISO639 language array', 'createISO639LanguageArray', error);
        captureException(new Error(message, cause));
        return [];
    }
};

export const filterVoices = (
    voices: VoiceInterface[] | null,
    language: string,
    genders: string[],
    tiers: Tier[],
    accents: string[]
): VoiceInterface[] => {
    try {
        if (language === MULTIPLE_LANGUAGES_OPTION_NAME || !voices?.length) {
            return [];
        }
        const filteredVoices = (voices ?? []).filter((voice: VoiceInterface) => {
            const languageCheck = voice.language.includes(language);
            const genderCheck = genders.includes(voice.labels.gender);
            const tierCheck = tiers.includes(voice.labels.tier);
            const accentCheck = accents.includes(voice.labels.accent);
            return languageCheck && genderCheck && tierCheck && accentCheck;
        });

        return filteredVoices;
    } catch (error) {
        const { message, cause } = formatSentryError(`Error filtering voices`, 'filterVoices', error);
        captureException(new Error(message, cause));
        throw error;
    }
};

// Formats voices to show premium voices at top, and in alphabetical order by accent within the voice dropdown container.
export const formatVoices = (voices: VoiceInterface[] | null): VoiceInterface[] => {
    const isPremiumVoices = (voices ?? []).map((voice) => ({
        ...voice,
        isPremium: getIsPremiumVoice(voice)
    }));

    isPremiumVoices.sort((a, b) => {
        if (a.isPremium !== b.isPremium) {
            return a.isPremium ? -1 : 1;
        }
        return a.labels.accent.localeCompare(b.labels.accent);
    });

    return isPremiumVoices;
};

export const retrieveVoices = async (): Promise<VoiceInterface[]> => {
    const maxRetries = 3;
    let attempts = 0;

    while (attempts < maxRetries) {
        try {
            const response = await axios.get(`${process.env.VOICE_COLLECTION_ENDPOINT}`);
            const voices = response.data;

            return voices;
        } catch (error) {
            attempts++;

            const { message, cause } = formatSentryError(
                `Error retrieving voices. Attempt ${attempts} out of ${maxRetries}`,
                'retrieveVoices',
                error
            );
            captureException(new Error(message, cause));

            if (attempts >= maxRetries) {
                throw error;
            }

            // Exponentially gives time for network issue to resolve. Without a delay may run through all 3 attempts instantaneously.
            await new Promise((resolve) => setTimeout(resolve, 1000 * attempts));
        }
    }

    return [];
};

export const getIsPremiumVoice = (voice: VoiceInterface): boolean => {
    return tierMapping[voice.labels.tier as Tier];
};

export const getIsPremiumVoiceByVoiceCode = (voiceCode: string, voices: VoiceInterface[]): boolean => {
    const voice = voices.find((voice) => voice.voiceCode === voiceCode);
    if (!voice) {
        captureException(new Error(formatSentryMessage('Voice not found', 'getIsPremiumVoiceByVoiceCode')));
        return false;
    }
    return getIsPremiumVoice(voice);
};

export const getLanguageAccentsMapFromVoices = (voices: VoiceInterface[]): LanguageAccentsMapType => {
    const updatedLanguageAccentsMap = INITIAL_LANUAGE_ACCENTS_MAP;

    voices.forEach((voice) => {
        // * if the accent is not already in the accents array for the language, add it
        if (!updatedLanguageAccentsMap[voice.language[0] as SupportedISO639LanguagesType].includes(voice.labels.accent as AccentEnum)) {
            updatedLanguageAccentsMap[voice.language[0] as SupportedISO639LanguagesType].push(voice.labels.accent as AccentEnum);
        }
    });

    return updatedLanguageAccentsMap;
};
