import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { AuthContextInterface, CmsIntegrationSettings, GroupInterface, RssFeedFinderResponseInterface } from '../types/types';
import { DEFAULT_GROUP_SITE_URL } from '../utils/constants/constants';
import { formatSentryError } from './telemetryHelpers';

/**
 * Utility function to detect RSS feed URL from a given site URL
 * If an optional AbortSignal is passed, the request will be aborted if the signal is aborted
 * @param siteUrl
 * @param signal
 * @returns
 */
export async function detectFeed(siteUrl: string, signal?: AbortSignal): Promise<string | null> {
    try {
        const response = await axios.post('/api/v2/rss/rss-feed-finder', { siteUrl }, { signal });
        const data = response.data as RssFeedFinderResponseInterface;
        if (response.status !== 200) {
            return null;
        }
        return data.rssUrl;
    } catch (error) {
        const { message, cause } = formatSentryError('Error detecting RSS feed', 'detectFeed', error);
        captureException(new Error(message, cause));
        return null;
    }
}

// * suggest an rss feed if there isn't a configured cmsIntegration
export const updateSuggestedRssFeed = async (
    cmsIntegrationSettings: AuthContextInterface['rssIntegrationSettings'],
    group: AuthContextInterface['group'],
    suggestedRssFeed: AuthContextInterface['suggestedRssFeed'],
    setSuggestedRssFeed: (rssFeed: AuthContextInterface['suggestedRssFeed']) => void
) => {
    if (cmsIntegrationSettings?.length) {
        // * if there is a configured cmsIntegration, then there is no need to suggest an rss feed
        setSuggestedRssFeed(null);
    } else if (group?.siteUrl && group?.siteUrl !== DEFAULT_GROUP_SITE_URL && suggestedRssFeed === undefined) {
        // * if there is a valid group siteUrl, and this code hasn't tried to detect an rss feed yet (still undefined), then try to detect one to suggest
        const detectedRssFeed = await detectFeed(group.siteUrl);

        if (detectedRssFeed) {
            setSuggestedRssFeed(detectedRssFeed);
        } else {
            setSuggestedRssFeed(null);
        }
    }
};
