import { MetaTagsInterface, NarrationInterface } from '../types/types';

export const defaultMetaTags: MetaTagsInterface = {
    title: 'Adauris Dashboard',
    description: 'Adauris lets you narrate your written content and distribute it to your customers.',
    ogTitle: 'Adauris Dashboard',
    ogDescription: 'Adauris lets you narrate your written content and distribute it to your customers.',
    ogImage: '/logo_og.png'
};

export const defaultSpanishMetaTags: MetaTagsInterface = {
    title: 'Este articulo fue convertido a audio con Ad Auris',
    description: 'Para convertir mas articulos a audio, envianos un mensaje de WhatsApp con su enlace a +1-548-457-1235',
    ogTitle: 'Este articulo fue convertido a audio con Ad Auris',
    ogDescription: 'Para convertir mas articulos a audio, envianos un mensaje de WhatsApp con su enlace a +1-548-457-1235',
    ogImage: '/logo_og.png'
};

export function buildMetaTagsFromNarration(narration: NarrationInterface | null): MetaTagsInterface {
    let tags = defaultMetaTags;

    if (narration) {
        if (narration.title) {
            const title = `${narration.title} - Narrated by Ad Auris`;
            tags.title = title;
            tags.ogTitle = title;
        }

        if (narration.image) {
            tags.ogImage = narration.image;
        }

        if (narration.siteName) {
            const description = `This audio of ${narration.siteName}'s article was generated by Ad Auris.`;
            tags.description = description;
            tags.ogDescription = description;
        }
    }

    return tags;
}

export function buildSpanishWhatsAppMetaTags(narration: NarrationInterface | null): MetaTagsInterface {
    let tags = defaultSpanishMetaTags;

    if (narration) {
        if (narration.title) {
            const title = `${narration.title} - este articulo fue convertido a audio con Ad Auris`;
            tags.title = title;
            tags.ogTitle = title;
        }

        if (narration.image) {
            tags.ogImage = narration.image;
        }
    }

    return tags;
}
