import { NavItem } from './NavItem';
import { Box, BoxProps, CloseButton, Flex, VStack, chakra, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import LinkedLogo from '../../Logo/LinkedLogo/LinkedLogo';
import { ALL_ROUTES } from '../../../types/types';
import { useAuth } from '../../../context/AuthContext';
import { useFeatureFlagsContext } from '../../../context/hooks/FeatureFlagsHook';
import DarkModeLogo from '../../Logo/DarkModeLogo/DarkModeLogo';
import { LEAVE_FIRST_USE_MODAL_MESSAGE } from '../../../utils/constants/copyConstants';
import { useMemo, useState } from 'react';
import { useGlobalNarrationContext } from '../../../context/hooks/GlobalNarrationHook';
import { ContinueTutorialModal } from '../../Modals/TutorialModals/ContinueTutorialModal';
import { allowResumeTutorial } from '../../../helpers/featureFlagHelpers';
import { SIDEBAR_WIDTH } from '../../../utils/constants/stylingConstants';
import { CreditUsageCounter } from '../../Cards/CreditUsage/CreditUsageCounter';
import { CANNY_URL } from '../../../utils/constants/constants';

interface SidebarContentProps {
    boxProps?: BoxProps;
    onClose?: () => void;
}

export const SidebarContent = ({ boxProps, onClose }: SidebarContentProps & BoxProps) => {
    const { signOut, rssIntegrationSettings, hubspotIntegrationSettings } = useAuth();
    const { isEnabledAudioStudio, hideAutoNarrations, paymentTierName, isFeaturePaywalled } = useFeatureFlagsContext();
    const { successNarrations } = useGlobalNarrationContext();
    const [triggerOpenTutorialModal, setTriggerOpenTutorialModal] = useState(false);

    const onClickLogout = () => {
        if (isFeaturePaywalled.UNINTRUSIVE_PAGE_LEAVE) {
            if (window.confirm(LEAVE_FIRST_USE_MODAL_MESSAGE)) {
                signOut();
                // * Return true to allow the routing to continue
                return true;
            } else {
                // * Return false to prevent the routing from continuing
                return false;
            }
        } else {
            signOut();
            // * Return true to allow the routing to continue
            return true;
        }
    };

    const onClickTutorial = () => {
        setTriggerOpenTutorialModal(true);
        // * Return false to stop the routing from continuing. Routing is handled in the tutorial modal.
        return false;
    };

    const onClickGiveUsFeedback = () => {
        // * Return false to stop the routing from continuing. Routing is handled in the feedback modal.
        return false;
    };

    const planName = useMemo(() => {
        if (paymentTierName) {
            if (paymentTierName === 'Tutorial') return paymentTierName;
            else {
                return paymentTierName + ' Plan';
            }
        } else {
            return '';
        }
    }, [paymentTierName]);

    const showResumeTutorialButton = useMemo(
        () => allowResumeTutorial(isFeaturePaywalled, successNarrations, rssIntegrationSettings, hubspotIntegrationSettings),
        [isFeaturePaywalled, successNarrations, rssIntegrationSettings, hubspotIntegrationSettings]
    );

    return (
        <Box
            as="nav"
            pos="fixed"
            top="0"
            left="0"
            zIndex={'sticky'}
            overflowX="hidden"
            overflowY="auto"
            bgColor={'brand.darkComponentBg'}
            borderColor={'brand.darkComponentOutline'}
            borderRightWidth="1px"
            w={SIDEBAR_WIDTH}
            data-testid="sidebar-container"
            height={'100%'}
            role="navigation"
            {...boxProps}
        >
            <VStack height={'100%'}>
                {!!onClose && <CloseButton alignSelf={'end'} m={4} onClick={onClose} />}
                <Flex direction={'column'} alignSelf={'start'} w={'100%'} height={'100%'}>
                    <Flex direction={'column'} px="6" py="5" align="flex-start" mb={8} mt={6} ml="2">
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <LinkedLogo icon={<DarkModeLogo boxSize="180px" />} href={ALL_ROUTES.HOME} />
                        </motion.div>
                        {/* ml is hard coded in pixels here because I'm aligning the start of this text with the start of Adauris word in the logo, 
                        which has the icon on its left side, so we can't just left/start align  */}
                        <Text alignSelf={'flex-start'} ml="29px" fontSize={'sm'}>
                            {planName}
                        </Text>
                    </Flex>
                    <Flex direction="column" fontSize="sm" color="gray.600" aria-label="Main Navigation" flex={1} pb={4} justifyContent="flex-end">
                        <Flex direction={'column'} gap={6}>
                            {showResumeTutorialButton && (
                                <>
                                    <NavItem dataTestId={'tutorial'} route={ALL_ROUTES.HOME} callbackBeforeRouting={onClickTutorial} disableHighlight>
                                        Resume Tutorial ✨
                                    </NavItem>
                                </>
                            )}
                            <Flex direction="column">
                                {isEnabledAudioStudio && !isFeaturePaywalled.AUDIO_STUDIO_PAGE && (
                                    <NavItem dataTestId={'audioStudio'} route={ALL_ROUTES.AUDIO_STUDIO}>
                                        Audio Studio
                                    </NavItem>
                                )}
                                {!isFeaturePaywalled.AUDIO_CATALOGUE_PAGE && (
                                    <NavItem dataTestId={'audioCatalogue'} route={ALL_ROUTES.AUDIO_CATALOGUE}>
                                        Audio Catalogue
                                    </NavItem>
                                )}
                                {!isFeaturePaywalled.ANALYTICS_PAGE && (
                                    <NavItem dataTestId={'analytics'} route={ALL_ROUTES.ANALYTICS}>
                                        Analytics
                                    </NavItem>
                                )}
                            </Flex>

                            <Flex direction="column">
                                {!hideAutoNarrations && !isFeaturePaywalled.INTEGRATIONS_PAGE && (
                                    <NavItem dataTestId={'integrations'} route={ALL_ROUTES.INTEGRATIONS}>
                                        Integrations
                                    </NavItem>
                                )}
                                {!isFeaturePaywalled.DISTRIBUTION_PAGE && (
                                    <NavItem dataTestId={'distribution'} route={ALL_ROUTES.DISTRIBUTION}>
                                        Distribution
                                    </NavItem>
                                )}
                                {!isFeaturePaywalled.PRONUNCIATION_LIBRARY_PAGE && (
                                    <NavItem dataTestId={'pronunciationLibrary'} route={ALL_ROUTES.PRONUNCIATION_LIBRARY}>
                                        Pronunciation Library
                                    </NavItem>
                                )}
                            </Flex>
                        </Flex>

                        <Flex direction={'column'}>
                            <chakra.hr my={4} borderColor={'brand.darkComponentOutline'} />
                            <CreditUsageCounter />

                            <NavItem
                                dataTestId={'giveFeedback'}
                                route={ALL_ROUTES.HOME}
                                callbackBeforeRouting={onClickGiveUsFeedback}
                                disableHighlight
                                additionalProps={{
                                    as: 'a',
                                    href: CANNY_URL,
                                    'data-canny-link': '', // * This is a custom attribute that Canny uses to connect auth between our system and theirs, the value is not important
                                    target: '_blank'
                                }}
                            >
                                Give us Feedback
                            </NavItem>

                            {!isFeaturePaywalled.PAYMENTS && (
                                <NavItem dataTestId={'settings'} route={ALL_ROUTES.SETTINGS}>
                                    Settings and Plans
                                </NavItem>
                            )}

                            <NavItem dataTestId={'logout'} route={ALL_ROUTES.AUTH_PAGE} callbackBeforeRouting={onClickLogout}>
                                Logout
                            </NavItem>
                        </Flex>
                    </Flex>
                </Flex>
            </VStack>
            <ContinueTutorialModal triggerOnOpen={triggerOpenTutorialModal} setTriggerOnOpen={setTriggerOpenTutorialModal} />
        </Box>
    );
};
