import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { FirestoreDocumentIdType, GroupInterface, NarrationSettingsInterface } from '../types/types';
import { DEFAULT_NARRATION_SETTINGS } from '../utils/constants/audioContants';
import { patchGroup } from './groupHelpers';
import { formatSentryError } from './telemetryHelpers';

export const getNarrationSettingsById = async (narrationSettingsId: FirestoreDocumentIdType) => {
    try {
        const response = await axios.get(`/api/v2/narration-settings/get/${narrationSettingsId}`);
        return response.data as NarrationSettingsInterface;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API narration-settings/get', 'getNarrationSettingsById', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const getAllNarrationSettingsByGroup = async (groupId: FirestoreDocumentIdType) => {
    try {
        const response = await axios.get(`/api/v2/narration-settings/get/groups/${groupId}`);
        return response.data as NarrationSettingsInterface[];
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API narration-settings/get', 'getNarrationSettingsById', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const postNarrationSettings = async (payload: Partial<NarrationSettingsInterface>) => {
    try {
        // * assign default values
        payload = { ...DEFAULT_NARRATION_SETTINGS, ...payload };

        const response = await axios.post('/api/v2/narration-settings/post', payload);
        return response.data as FirestoreDocumentIdType;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API narration-settings/post/', 'postNarrationSettings', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const patchNarrationSettings = async (narrationSettingsId: FirestoreDocumentIdType, payload: Partial<NarrationSettingsInterface>) => {
    try {
        const response = await axios.patch(`/api/v2/narration-settings/patch/${narrationSettingsId}`, payload);
        return response.data as FirestoreDocumentIdType;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API narration-settings/patch/', 'patchNarrationSettings', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const deleteNarrationSettings = async (narrationSettingsId: FirestoreDocumentIdType) => {
    try {
        const response = await axios.delete(`/api/v2/narration-settings/delete/${narrationSettingsId}`);
        return response.data as FirestoreDocumentIdType;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API narration-settings/delete/', 'deleteNarrationSettings', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

/** this function is used to create a narrationSettings doc for older groups that don't have one
 * @param group - the group to add narrationSettings to
 * @param allNarrationSettings - the narrationSettings docs associated with the group (could be created for integrations)
 * */
export const getAndSetNarrationSettingsForGroup = async (
    group: GroupInterface,
    setGroup: (group: GroupInterface) => void,
    allNarrationSettings: NarrationSettingsInterface[],
    setNarrationSettings: (narrationSettings: NarrationSettingsInterface) => void
): Promise<void> => {
    try {
        // * if there is already a narrationSettingsId attached to the group, return that document
        const existingNarrationSettingsForGroup = allNarrationSettings.find((narrationSetting) => narrationSetting.id === group.narrationSettingsId);
        if (existingNarrationSettingsForGroup) {
            setNarrationSettings(existingNarrationSettingsForGroup);
            return;
        }
        // * if there is no narrationSettingsId attached to the group, create a new narrationSettings doc and attach it to the group
        else {
            const narrationSettingsPayload: Partial<NarrationSettingsInterface> = {
                groupId: group.id,
                name: 'Group'
            };

            // * if there is a wordList in any of the narrationSettingsDocs, use that wordList. This means that the user has already set up a wordList on a narrationSettings doc attached to an integration
            const wordList = allNarrationSettings.find((doc) => doc.wordList && Object.keys(doc.wordList).length > 0)?.wordList;
            if (wordList) {
                narrationSettingsPayload.wordList = wordList;
            }

            // * create a new narrationSettings doc
            const narrationSettingsId = await postNarrationSettings(narrationSettingsPayload);
            // * attach the new narrationSettings doc to the group
            const groupWithNarrationSettingsId = await patchGroup(group.id, { narrationSettingsId });

            // * fetch the newly created narrationSettings doc since we only have the id
            const narrationSettingsForGroup = await getNarrationSettingsById(narrationSettingsId);

            setGroup(groupWithNarrationSettingsId);
            setNarrationSettings(narrationSettingsForGroup);

            return;
        }
    } catch (err) {
        const { message, cause } = formatSentryError(
            'Error getting and setting narration settings on an older group',
            'getAndSetNarrationSettingsForGroup',
            err
        );
        captureException(new Error(message, cause));
        throw err;
    }
};
