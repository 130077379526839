import '../styles/globals.css';
import { AppProps } from 'next/app';
import MetaHead from '../components/Html/MetaHead';
import { useEffect } from 'react';
import AppMultiProviderContainer from './_app-child-containers/_appMultiProviderContainer';
import { initPostHog } from '../config/postHog/postHog';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

function MyApp(appProps: AppProps) {
    useEffect(() => {
        const posthog = initPostHog();

        if (typeof window !== 'undefined') {
            posthog.capture('$pageview');
        }
    }, []);

    return (
        <div>
            <MetaHead {...appProps} />
            <AppMultiProviderContainer {...appProps} />
            <Analytics />
            <SpeedInsights />
        </div>
    );
}

export default MyApp;
