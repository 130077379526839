import posthog from 'posthog-js';
import { User } from 'firebase/auth';
import { formatSentryError } from '../../helpers/telemetryHelpers';
import { captureException } from '@sentry/nextjs';

export const initPostHog = () => {
    if (typeof window !== 'undefined') {
        const POST_HOG_KEY = process.env.POST_HOG_KEY;

        try {
            if (!POST_HOG_KEY) {
                throw new Error('ENV variable not set, unable to initialize Posthog client library');
            } else {
                posthog.init(POST_HOG_KEY, {
                    api_host: 'https://app.posthog.com'
                });
                console.log('--------POSTHOG has been initalized---------');
            }
        } catch (error) {
            const { message, cause } = formatSentryError('Error starting Posthog - API key not defined', 'initPostHog', error);
            captureException(new Error(message, cause));
        }
    }
    return posthog;
};

export const instantiatePostHog = (user: User): void => {
    try {
        if (!process.env.POST_HOG_KEY) {
            throw new Error('ENV variable not set, unable to identify user');
        } else {
            const userID = user.uid as string;
            const userEmail = user.email as string;
            const displayName = user.displayName as string;
            posthog.identify(userID, { email: userEmail, displayName: displayName }, { id: userID }), { environment: process.env.APP_ENV };
            posthog.people.set({ email: userEmail });
            console.log('--------POSTHOG user added---------');
        }
    } catch (error) {
        const { message, cause } = formatSentryError('Error starting Posthog - API key not defined', 'initPostHog', error);
        captureException(new Error(message, cause));
    }
};
