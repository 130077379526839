export const invertObject = (obj: Record<any, any>) => {
    const result: Record<any, any> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[obj[key]] = key;
        }
    }
    return result;
};

export const isDeepEqual = (object1: any, object2: any) => {
    if (!isObject(object1) || !isObject(object2)) return object1 === object2;

    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (var key of objKeys1) {
        // @ts-expect-error temp ignore -issue after ugprading TS from 4.6 to 5.5
        const value1 = object1[key];
        // @ts-expect-error temp ignore -issue after ugprading TS from 4.6 to 5.5
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);

        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false;
        }
    }
    return true;
};

export const isObject = (object: unknown) => {
    return object != null && typeof object === 'object';
};
