import { NarrationBlockInterface, NarrationBlockSourceTypeEnum, NarrationTypeEnum } from '../types/types';

export const getNarrationTypeFromBlocks = (blocks: NarrationBlockInterface[]): NarrationTypeEnum => {
    let hasAudioBlock = false;
    let hasTextBlock = false;

    for (const block of blocks) {
        // * undefined sourceType is considered as Text since this was the default value before the sourceType was added
        if ((!hasTextBlock && !block.sourceType) || block.sourceType === NarrationBlockSourceTypeEnum.Text) {
            hasTextBlock = true;
        }
        if (!hasAudioBlock && block.sourceType === NarrationBlockSourceTypeEnum.Audio) {
            hasAudioBlock = true;
        }
    }

    if (hasAudioBlock && hasTextBlock) {
        return NarrationTypeEnum.PartialUploadedAudioFile;
    } else if (hasAudioBlock) {
        return NarrationTypeEnum.UploadedAudioFile;
    } else {
        return NarrationTypeEnum.Blocks;
    }
};
