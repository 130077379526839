import { Box, Drawer, DrawerContent, DrawerOverlay, useDisclosure, Spinner, Center, VStack, IconButton } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { SidebarContent } from './Sidebar/SidebarContent';
import { useAuth } from '../../context/AuthContext';
import { FiMenu } from 'react-icons/fi';
import { ALL_ROUTES } from '../../types/types';

interface DashboardWrapperProps {
    content: ReactNode;
}

const MAIN_PADDING_X = 12;
const MAIN_PADDING_X_MOBILE = 4;
const MAIN_PADDING_TOP = 16;
const MAIN_PADDING_BOTTOM = 24;

export default function DashboardWrapper({ content }: DashboardWrapperProps) {
    const sidebar = useDisclosure();
    const router = useRouter();
    const { user, loading } = useAuth();

    useEffect(() => {
        // * disallow users from using the app if they are not logged in
        if (!loading && !user && router.pathname !== ALL_ROUTES.SIGNIN && router.pathname !== ALL_ROUTES.SIGNUP) {
            if (router.pathname === ALL_ROUTES.SETTINGS) {
                // * refresh the page when logging out from the plans and pricing page because we need to ensure the Calendly Script gets removed
                // * see ScriptWithCleanup.tsx for more details. For some reason, the edge case of logging out does not function as expected and needs this extra logic
                // * more info here: https://linear.app/ad-auris/issue/ENG-1219/bug-calendly-widget-isnt-dismounting
                window.location.href = router.basePath + ALL_ROUTES.AUTH_PAGE;
            } else {
                router.push(ALL_ROUTES.AUTH_PAGE);
            }
        }
    }, [user, loading, router]);

    return (
        <div>
            <IconButton
                variant={'ghost'}
                aria-label="Menu"
                position={'fixed'}
                onClick={sidebar.onOpen}
                icon={<FiMenu />}
                size="lg"
                data-testid="sidebar-drawer-menu-button"
                display={{ base: 'inline-flex', md: 'none' }}
                zIndex={3}
            />
            {loading || !user ? (
                <Center p={MAIN_PADDING_X}>
                    <Spinner />
                </Center>
            ) : (
                <Box as="section" minH="100%">
                    <SidebarContent boxProps={{ display: { base: 'none', md: 'unset' } }} />
                    <Drawer isOpen={sidebar.isOpen} onClose={sidebar.onClose} placement="left">
                        <DrawerOverlay />
                        <DrawerContent>
                            <SidebarContent boxProps={{ w: 'full' }} onClose={sidebar.onClose} />
                        </DrawerContent>
                    </Drawer>
                    <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
                        <VStack
                            as="main"
                            px={{ base: MAIN_PADDING_X_MOBILE, sm: MAIN_PADDING_X }}
                            pt={MAIN_PADDING_TOP}
                            pb={MAIN_PADDING_BOTTOM}
                            alignItems={'flex-start'}
                        >
                            {content}
                        </VStack>
                    </Box>
                </Box>
            )}
        </div>
    );
}
