export const REGISTER_EVENT_API_URL = '/api/v1/register-event';

export enum EVENT_NAME {
    set = '$set', // * This is a default posthog event name that is used to set user properties. https://posthog.com/docs/getting-started/person-properties
    betaAccessAttempt = 'betaAccessAttempt',
    buttonClick = 'buttonClick',
    textInput = 'textInput',
    menuClick = 'menuClick',
    menuOptionClick = 'menuOptionClick',
    speedChange = 'speedChange',
    pageLoad = 'pageLoad',
    signup = 'signUp',
    signin = 'signIn',
    logOut = 'logOut',
    requestNarrationCreation = 'requestNarrationCreation',
    attemptNarrationCreation = 'attemptNarrationCreation',
    deleteNarration = 'deleteNarration',
    flagNarration = 'flagNarration',
    gotoNextNarration = 'gotoNextNarration',
    gotoPreviousNarration = 'gotoPreviousNarration',
    play = 'play',
    pause = 'pause',
    seeked = 'seeked',
    timeUpdate = 'timeUpdate',
    ended = 'ended',
    liked = 'liked',
    beforeUnload = 'beforeUnload',
    goBackSeconds = 'goBackSeconds',
    goForwardSeconds = 'goForwardSeconds',
    clickLink = 'clickLink',
    selectNarration = 'selectNarration',
    filterNarration = 'filterNarration',
    session_start = 'startSession',
    secondsListenedUpdate = 'secondsListenedUpdate',
    percentage = 'percentage',
    shareNarration = 'shareNarration',
    lockedFeatureAttempt = 'lockedFeatureAttempt',
    subscribeToPaymentPlan = 'subscribeToPaymentPlan',
    cancelPaymentPlan = 'cancelPaymentPlan',
    setSubscriptionTier = 'setSubscriptionTier',
    setSubscriptionChange = 'setSubscriptionChange',
    addPrenarratedNarration = 'addPrenarratedNarration',
    clickTryForFree = 'clickTryForFree',
    clickSignIn = 'clickSignIn',
    copyCustomShortcutEmailAddress = 'copyCustomShortcutEmailAddress',
    saveB2BNarrationForLater = 'saveB2BNarrationForLater',
    clickSaveNarrationForLater = 'clickSaveNarrationForLater',
    attemptAddQueuedB2BNarration = 'attemptAddQueuedB2BNarration',
    setUserProperty = 'setUserProperty',
    clickBrowseFromCreateNarrationForm = 'clickBrowseFromCreateNarrationForm',
    submitDailyRoundupForm = 'submitDailyRoundupForm',
    clickCreateDailyRoundup = 'clickCreateDailyRoundup',
    clickPlayPauseDailyRoundupDemo = 'clickPlayPauseDailyRoundupDemo',
    clickAddToSlack = 'clickAddToSlack',
    clickPlayPauseWhatsAppNarration = 'clickPlayPauseWhatsAppNarration',
    clickWhatsAppPhoneNumber = 'clickWhatsAppPhoneNumber',
    clickSendMessageToWhatsapp = 'clickSendMessageToWhatsapp',
    visitPublicationSite = 'visitPublicationSite',
    clickRelatedContent = 'clickRelatedContent',
    clickCreateFreeAccount = 'clickCreateFreeAccount',
    saveToAccount = 'saveToAccount',
    clickRetryLandingPageNarration = 'clickRetryLandingPageNarration',
    addAnotherArticle = 'addAnotherArticle',
    discoverMore = 'discoverMore',
    directToArticle = 'directToArticle',
    directToTopicPodcast = 'directToTopicPodcast',
    showUrlCompatibilityPopup = 'showUrlCompatibilityPopup',
    thumbsUpFeature = 'thumbsUpFeature',
    downloadAudio = 'downloadAudio',
    openRSSFeed = 'openRSSFeed',
    clickDisabledFeatureBeforeGeneratingAudio = 'clickDisabledFeatureBeforeGeneratingAudio',
    sidebarNavItemClick = 'sidebarNavItemClick',
    validateRSS = 'validateRSS',
    registerRSSFeed = 'registerRSSFeed',
    copyIframeCode = 'copyIframeCode',
    clickPodcastGuide = 'clickPodcastGuide',
    clickPlayAppLinkOnAuthPage = 'clickPlayAppLinkOnAuthPage',
    clickPlayAppLinkOnRssFeedValidationFailure = 'clickPlayAppLinkOnRssFeedValidationFailure',
    uploadAudio = 'uploadAudio',
    copyPodcastRssUrl = 'copyPodcastRssUrl',
    deleteWordListItem = 'deleteWordListItem',
    updateWordListItem = 'updateWordListItem',
    addWordListItem = 'addWordListItem',
    displayedCtaToSubmitRssFormBeforeEditingWordList = 'displayedCtaToSubmitRssFormBeforeEditingWordList',
    clickAuthenticateButton = 'clickAuthenticateButton',
    updateWidgetPrimaryColor = 'updateWidgetPrimaryColor',
    textTransformationFeedback = 'textTransformationFeedback',
    customTransformedAudioFeedback = 'customTransformedAudioFeedback',
    requestEnterpriseAccessCode = 'requestEnterpriseAccessCode',
    transformTextRequest = 'transformTextRequest',
    parseTextByNewLine = 'parseTextByNewLine',
    callWordsmithWithParameters = 'callWordsmithWithParameters',
    callWordsmithSummarize = 'callWordsmithSummarize',
    saveWordsmithParameters = 'saveWordsmithParameters',
    editNarration = 'editNarration',
    clickUpgradeCta = 'clickUpgradeCta',
    clickAddAutoNarrationsInAutoNarrations = 'clickAddAutoNarrationsInAutoNarrations',
    clickSaveTitleAndLanguageInAutoNarrations = 'clickSaveTitleAndLanguageInAutoNarrations',
    clickSaveAudioSettingsInAutoNarrations = 'clickSaveAudioSettingsInAutoNarrations',
    clickSaveListenAndConfirmSettingsInAutoNarrations = 'clickSaveListenAndConfirmSettingsInAutoNarrations',
    clickSaveConnectWithYourPublicationInAutoNarrations = 'clickSaveConnectWithYourPublicationInAutoNarrations',
    chooseScriptedNarrationInAudioStudio = 'chooseScriptedNarrationInAudioStudio',
    chooseVerbatimNarrationInAudioStudio = 'chooseVerbatimNarrationInAudioStudio',
    submitContentStepInAudioStudio = 'submitContentStepInAudioStudio',
    submitScriptStepInAudioStudio = 'submitScriptStepInAudioStudio',
    saveToAudioCatalogueInAudioStudio = 'saveToAudioCatalogueInAudioStudio',
    copyShareUrlInAudioStudio = 'copyShareUrlInAudioStudio',
    downloadAudioInAudioStudio = 'downloadAudioInAudioStudio',
    downloadButtonClick = 'downloadButtonClick',
    saveDraftInAudioStudio = 'saveDraftInAudioStudio',
    clickManualSaveDraftButtonInAudioStudio = 'clickManualSaveDraftButtonInAudioStudio',
    selectVoiceCode = 'selectVoiceCode',
    clickHomePageCard = 'clickHomePageCard',
    clickAudioStudioPaymentButton = 'clickAudioStudioPaymentButton',
    hitPaywallLock = 'hitPaywallLock',
    clickBuyMoreAudioStudioFiles = 'clickBuyMoreAudioStudioFiles',
    requestDataReport = 'requestDataReport',
    // * The following events are using the new naming convention of category:object_action
    // * For the EVENT_NAME key: the colon is replaced with an underscore and snakecase is replaced with camelCase
    audioStudio_clickDeleteBlock = 'audio_studio:click_delete_block',
    audioStudio_clickComingSoonCard = 'audio_studio:click_coming_soon_card',
    audioStudio_clickDisabledCard = 'audio_studio:click_disabled_card',
    audioStudio_clickSocialMediaPublicEpisode = 'audio_studio:click_social_media_public_episode',
    audioStudio_clickFileUploadCard = 'audio_studio:click_file_upload_card',
    analytics_clickDownloadCsv = 'analytics:click_download_csv',
    audioStudio_clickDailyNewsRundownCard = 'audio_studio:click_daily_news_rundown_card',
    audioStudio_clickAudioShortsForSocialCard = 'audio_studio:click_audio_shorts_for_social_card',
    audioStudio_clickDistributionTab = 'audio_studio:click_distribution_tab',
    clickYoutubeGuide = 'click_youtube_guide',
    audioStudio_clickPodcastSiteLink = 'audio_studio:click_podcast_site_link',
    audioStudio_clickPodcastSettingsButton = 'audio_studio:click_podcast_settings_button',
    audioStudio_clickGetEmbedCodeButton = 'audio_studio:click_get_embed_code_button',
    audioStudio_revertToDraft = 'audio_studio:revert_to_draft',
    tutorialModal_clickContinue = 'tutorial_modal:click_continue',
    tutorialModal_clickSkip = 'tutorial_modal:click_close',
    tutorialModal_clickOpen = 'tutorial_modal:click_open',
    tutorialModal_openOnLoad = 'tutorial_modal:open_on_load',
    authModal_clickAlreadyHaveAccount = 'auth_modal:click_already_have_account',
    authModal_clickDoNotHaveAccount = 'auth_modal:click_do_not_have_account',
    authModal_clickForgotPassword = 'auth_modal:click_forgot_password',
    audioStudio_selectedTemplateExample = 'audio_studio:selected_template_example',
    audioStudio_clickIntegrationsCTA = 'audio_studio:click_integrations_cta',
    audioCatalogue_clickCreateSingleAudio = 'audio_catalogue:click_create_single_audio',
    audioCatalogue_clickSetUpIntegration = 'audio_catalogue:click_set_up_integration',
    audioCatalogue_clickCreateAudio = 'audio_catalogue:click_create_audio',
    distributionPage_clickPodcastSupademoGuide = 'distribution_page:click_podcast_supademo_guide',
    audioStudio_clickEditAudio = 'audio_studio:click_edit_audio',
    integrations_clickRssIntegrationsCard = 'integrations:click_rss_integrations_card',
    integrations_clickNotifyWhenAvailable = 'integrations:click_notify_when_available',
    audioCatalogue_clickRenarrate = 'audio_catalogue:click_renarrate',
    audioCatalogue_clickRenarrateEntireAudio = 'audio_catalogue:click_renarrate_entire_audio',
    audioCatalogue_confirmRenarrateEntireAudio = 'audio_catalogue:confirm_renarrate_entire_audio',
    audioCatlogue_endRenarrateEntireAudio = 'audio_catalogue:end_renarrate_entire_audio',
    audioCatalogue_clickRenarratePortion = 'audio_catalogue:click_renarrate_portion',
    audioCatalogue_clickBackFromRenarrateEntireAudio = 'audio_catalogue:click_back_from_renarrate_entire_audio',
    rewriteScript_sendPrompt = 'rewrite_script:send_prompt',
    rewriteScript_undoLastPrompt = 'rewrite_script:undo_last_prompt',
    rewriteScript_confirmRewrite = 'rewrite_script:confirm_rewrite',
    rewriteScript_clickOpenChat = 'rewrite_script:click_open_chat',
    rewriteScript_clickCloseChat = 'rewrite_script:click_close_chat',
    rewriteScript_cancelCloseChat = 'rewrite_script:cancel_close_chat',
    wordsmith_callRewriteWithInstructions = 'wordsmith:call_rewrite_with_instructions',
    onboarding_clickSubmit = 'onboarding:click_submit',
    onboarding_clickSkip = 'onboarding:click_skip',
    endOfTutorialModal_clickShareOptions = 'end_of_tutorial_modal:click_share_options',
    endOfTutorialModal_clickIntegrations = 'end_of_tutorial_modal:click_explore_integrations',
    endOfTutorialModal_clickAnalytics = 'end_of_tutorial_modal:click_view_analytics',
    endOfTutorialModal_clickClose = 'end_of_tutorial_modal:click_close',
    endOfTutorialModal_open = 'end_of_tutorial_modal:open',
    endOfTutorial_sendEmail = 'end_of_tutorial:send_email',
    endOfTutorial_skipEmail = 'end_of_tutorial:skip_email',
    homePage_clickCannyLink = 'home_page:click_canny_link',
    settings_requestAdditionalAccountChange = 'settings:request_additional_account_change',
    settings_saveDisplayName = 'settings:save_display_name',
    settings_saveWorkspaceName = 'settings:save_workspace_name',
    settings_saveSiteUrl = 'settings:save_site_url',
    auth_errorSettingCoreWorkspaceDocuments = 'auth:error_setting_core_workspace_documents',
    integrations_clickHubspotIntegrationsCard = 'integrations:click_hubspot_integrations_card',
    integrations_clickConnectHubspot = 'integrations:click_connect_hubspot',
    integrations_deleteCmsIntegration = 'integrations:delete_cms_integration',
    integrations_cancelHubspotNarrationSettings = 'integrations:cancel_hubspot_narration_settings'
}

export enum USER_PROPERTY_NAME {
    hasPlayedAudio = 'hasPlayedAudio',
    hasGeneratedATextNarration = 'hasGeneratedATextNarration',
    hasGeneratedABlocksNarration = 'hasGeneratedABlocksNarration',
    hasClickedSocialMediaPublicEpisode = 'hasClickedSocialMediaPublicEpisode',
    hasClickedDownloadAudio = 'hasClickedDownloadAudio',
    hasCopiedIFrameCode = 'hasCopiedIFrameCode',
    workspaceName = 'workspaceName',
    siteUrl = 'siteUrl',
    displayName = 'displayName',
    personalLinkedInUrl = 'personalLinkedInUrl',
    howDidYouHearAboutUs = 'howDidYouHearAboutUs',
    howDidYouHearAboutUsOtherSpecification = 'howDidYouHearAboutUsOtherSpecification',
    personalOrBusinessAccountType = 'personalOrBusinessAccountType',
    freeTrialDaysOffered = 'freeTrialDaysOffered'
}

export const ORG_GROUP_TYPE = 'organization';
