import { useState, createContext, useContext, FC, ReactNode, useEffect, useMemo } from 'react';
import { getAllFeatureFlagsServerSide, getAllFeaturePaywalls, getPlanTierName, getSubscriptionAccessLevel } from '../../helpers/featureFlagHelpers';
import { FeatureFlagsContextInterface, FeatureFlagContextStateType, NarrationMethodEnum, StatusEnum } from '../../types/types';
import { LEAVE_FIRST_USE_MODAL_MESSAGE } from '../../utils/constants/copyConstants';
import {
    DEFAULT_IS_FEATURE_PAYWALLED_CONTEXT_VALUES,
    PAYWALLED_FEATURES,
    PLAN_ACCESS_LEVEL,
    POSTHOG_FEATURE_FLAGS
} from '../../utils/constants/featureFlagConstants';
import { LOWEST_GROUP_VERSION_ON_CREDIT_SYSTEM } from '../../utils/constants/pricingConstants';
import { useAuth } from '../AuthContext';
import { useGlobalNarrationContext } from './GlobalNarrationHook';

const FREE_ACCOUNT_NARRATION_CAPACITIES = {
    [NarrationMethodEnum.SingleTransformedTextRequest]: 1
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextInterface>({
    allowTransformedAudio: undefined,
    allowSectionedNarrationForm: undefined,
    hideAutoNarrations: undefined,
    allowYoutubeIntegrationV1: undefined,
    showWordsmithParameters: undefined,
    showNarrationShareButton: undefined,
    hasDistributedWidget: undefined,
    setHasDistributedWidget: () => {},
    hasSharedOnSocialMedia: undefined,
    setHasSharedOnSocialMedia: () => {},
    hasDownloadedAudio: undefined,
    setHasDownloadedAudio: () => {},
    isEnabledAudioStudio: false,
    refreshAllFeatureFlags: () => {},
    bypassPaymentPortal: undefined,
    paymentTierAccessLevel: undefined,
    isAtCapacityOnAudioStudio: undefined,
    availableAudioStudioNarrationCapacity: 0,
    usedAudioStudioNarrationCapacity: 0,
    paymentTierName: '',
    allowUnlimitedAudioStudio: undefined,
    isFeaturePaywalled: DEFAULT_IS_FEATURE_PAYWALLED_CONTEXT_VALUES,
    setAllowUnlimitedAudioStudio: () => {},
    setHasVisitedPlansAndPricing: () => {},
    hasSkippedTutorial: false,
    setHasSkippedTutorial: () => {},
    isUnlockAllAnalytics: undefined,
    showRewriteScript: undefined
});

// hook that we can use anywhere in the app
export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);

const FeatureFlagsProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { user, subscription, isNewUser, group, rssIntegrationSettings, hubspotIntegrationSettings } = useAuth();
    const { audioStudioNarrations, successNarrations } = useGlobalNarrationContext();

    // * posthog feature flags
    const [allowTransformedAudio, setAllowTransformedAudio] = useState<FeatureFlagContextStateType>(undefined);
    const [allowSectionedNarrationForm, setAllowSectionedNarrationForm] = useState<FeatureFlagContextStateType>(undefined);
    const [hideAutoNarrations, setHideAutoNarrations] = useState<FeatureFlagContextStateType>(undefined);
    const [allowYoutubeIntegrationV1, setAllowYoutubeIntegrationV1] = useState<FeatureFlagContextStateType>(undefined);
    const [showWordsmithParameters, setShowWordsmithParameters] = useState<FeatureFlagContextStateType>(undefined);
    const [showNarrationShareButton, setShowNarrationShareButton] = useState<FeatureFlagContextStateType>(undefined);
    const [bypassPaymentPortal, setBypassPaymentPortal] = useState<FeatureFlagContextStateType>(undefined);
    const [paymentTierAccessLevel, setPaymentTierAccessLevel] = useState<number | undefined>(undefined);
    const [allowUnlimitedAudioStudio, setAllowUnlimitedAudioStudio] = useState<FeatureFlagContextStateType>(undefined);
    const [enableFirstUseTutorialFlow, setEnableFirstUseTutorialFlow] = useState<FeatureFlagContextStateType>(undefined);
    const [hasDistributedWidget, setHasDistributedWidget] = useState<FeatureFlagContextStateType>(undefined);
    const [hasSharedOnSocialMedia, setHasSharedOnSocialMedia] = useState<FeatureFlagContextStateType>(undefined);
    const [hasDownloadedAudio, setHasDownloadedAudio] = useState<FeatureFlagContextStateType>(undefined);
    const [isUnlockAllAnalytics, setIsUnlockAllAnalytics] = useState<FeatureFlagContextStateType>(undefined);
    const [showRewriteScript, setShowRewriteScript] = useState<FeatureFlagContextStateType>(undefined);

    // * non-posthog feature flags
    const [paymentTierName, setPaymentTierName] = useState<string>('');
    const [isFeaturePaywalled, setIsFeaturePaywalled] = useState<Record<PAYWALLED_FEATURES, boolean | undefined>>(
        DEFAULT_IS_FEATURE_PAYWALLED_CONTEXT_VALUES
    ); // all paywalls for the user's current tier
    const [hasVisitedPlansAndPricing, setHasVisitedPlansAndPricing] = useState<boolean>(false); // * currently used for tutorial
    const [hasSkippedTutorial, setHasSkippedTutorial] = useState<boolean>(false); // * currently used for tutorial

    const usedAudioStudioNarrationCapacity = useMemo(() => {
        // ! This is going to start to become more problematic. We deferred the decision to migrate existing users but
        // ! its going to get harder to maintain this code. We will likely need direction on this soon.
        // TODO - Cleak this up, see above note.
        if (group && group.version < LOWEST_GROUP_VERSION_ON_CREDIT_SYSTEM) {
            if (audioStudioNarrations) {
                // * if there is only one draft, then the user has not used any capacity. We do not want to block the first use flow. For further use, we will include the draft in the count
                if (audioStudioNarrations.length === 1 && audioStudioNarrations[0].status === StatusEnum.Draft) {
                    return 0;
                }
                return audioStudioNarrations.length;
            } else {
                return 0;
            }
        } else {
            if (audioStudioNarrations && audioStudioNarrations.length === 1 && audioStudioNarrations[0].status === StatusEnum.Draft) {
                return 0;
            }

            if (group?.narrationUsage?.audioStudioNarrations && group.narrationUsage.autoNarrations) {
                return group.narrationUsage.audioStudioNarrations.totalPurchased + group.narrationUsage.autoNarrations.totalPurchased;
            } else {
                return 0;
            }
        }
    }, [audioStudioNarrations, group]);

    const availableAudioStudioNarrationCapacity = useMemo(() => {
        if (allowUnlimitedAudioStudio || bypassPaymentPortal) {
            return Infinity; // javascript's global var Infinity is a "number" type
        } else if (group && group.version < LOWEST_GROUP_VERSION_ON_CREDIT_SYSTEM) {
            // ! Same as the comment in usedAudioStudioNarrationCapacity, this is going to become a problem soon due the complexities of handling the old and new groups
            // TODO - Clean this up, see above note.
            if (
                paymentTierAccessLevel === PLAN_ACCESS_LEVEL['ENTERPRISE'] ||
                paymentTierAccessLevel === PLAN_ACCESS_LEVEL['TUTORIAL'] // * unlock in case user goes back to audio studio cards during tutorial
            ) {
                return Infinity; // javascript's global var Infinity is a "number" type
            } else {
                return FREE_ACCOUNT_NARRATION_CAPACITIES[NarrationMethodEnum.SingleTransformedTextRequest];
            }
        } else {
            if (group?.narrationUsage?.audioStudioNarrations) {
                return group.narrationUsage.audioStudioNarrations.currentAllocation;
            } else {
                return 0;
            }
        }
    }, [allowUnlimitedAudioStudio, bypassPaymentPortal, paymentTierAccessLevel, group]);

    const isAtCapacityOnAudioStudio = useMemo(() => {
        return usedAudioStudioNarrationCapacity >= availableAudioStudioNarrationCapacity;
    }, [usedAudioStudioNarrationCapacity, availableAudioStudioNarrationCapacity]);

    const handleFeatureFlagsLoad = async (): Promise<void> => {
        if (user?.uid) {
            const flags = await getAllFeatureFlagsServerSide(user.uid);
            if (flags) {
                setAllowSectionedNarrationForm(flags[POSTHOG_FEATURE_FLAGS.ALLOW_SECTIONED_NARRATIONS_FORM]);
                setHideAutoNarrations(flags[POSTHOG_FEATURE_FLAGS.HIDE_AUTO_NARRATIONS]);
                setAllowYoutubeIntegrationV1(flags[POSTHOG_FEATURE_FLAGS.ALLOW_YOUTUBE_INTEGRATION_V1]);
                setShowWordsmithParameters(flags[POSTHOG_FEATURE_FLAGS.SHOW_WORDSMITH_PARAMETERS]);
                setShowNarrationShareButton(flags[POSTHOG_FEATURE_FLAGS.SHOW_SHARE_NARRATION_BUTTON]);
                setAllowTransformedAudio(flags[POSTHOG_FEATURE_FLAGS.ALLOW_TRANSFORMED_AUDIO]);
                setBypassPaymentPortal(flags[POSTHOG_FEATURE_FLAGS.PAYMENT_PORTAL_BYPASS]);
                setAllowUnlimitedAudioStudio(flags[POSTHOG_FEATURE_FLAGS.ALLOW_UNLIMITED_AUDIO_STUDIO]);
                setEnableFirstUseTutorialFlow(flags[POSTHOG_FEATURE_FLAGS.ENABLE_FIRST_USE_TUTORIAL_FLOW]);
                setHasDistributedWidget(flags[POSTHOG_FEATURE_FLAGS.HAS_DISTRIBUTED_WIDGET]);
                setHasSharedOnSocialMedia(flags[POSTHOG_FEATURE_FLAGS.HAS_SHARED_ON_SOCIAL_MEDIA]);
                setHasDownloadedAudio(flags[POSTHOG_FEATURE_FLAGS.HAS_DOWNLOADED_AUDIO]);
                setIsUnlockAllAnalytics(flags[POSTHOG_FEATURE_FLAGS.UNLOCK_ALL_ANALYTICS]);
                setShowRewriteScript(flags[POSTHOG_FEATURE_FLAGS.SHOW_REWRITE_SCRIPT]);
            }
        }
    };

    const resetFeatureFlagsContext = () => {
        setAllowTransformedAudio(undefined);
        setAllowSectionedNarrationForm(undefined);
        setHideAutoNarrations(undefined);
        setAllowYoutubeIntegrationV1(undefined);
        setShowWordsmithParameters(undefined);
        setShowNarrationShareButton(undefined);
        setBypassPaymentPortal(undefined);
        setAllowUnlimitedAudioStudio(undefined);
        setEnableFirstUseTutorialFlow(undefined);
        setPaymentTierAccessLevel(undefined);
        setHasDistributedWidget(undefined);
        setHasSharedOnSocialMedia(undefined);
        setHasDownloadedAudio(undefined);
        setIsUnlockAllAnalytics(undefined);
        setShowRewriteScript(undefined);

        // * non-posthog feature flags
        setPaymentTierName('');
        setIsFeaturePaywalled(DEFAULT_IS_FEATURE_PAYWALLED_CONTEXT_VALUES);
        setHasVisitedPlansAndPricing(false);
        setHasSkippedTutorial(false);
    };

    const isEnabledAudioStudio = useMemo(() => {
        return !!allowSectionedNarrationForm || !!allowTransformedAudio;
    }, [allowSectionedNarrationForm, allowTransformedAudio]);

    useEffect(() => {
        handleFeatureFlagsLoad();
        if (!user) {
            // * reset all flags if user logs out
            resetFeatureFlagsContext();
        }
    }, [user]);

    // subscription tier handling
    useEffect(() => {
        // * set user's access level based on their subscription
        const accessLevel = getSubscriptionAccessLevel(subscription, bypassPaymentPortal, {
            isNewUser,
            enableFirstUseTutorialFlow,
            hasCreatedAudio: !!successNarrations?.length,
            hasVisitedPlansAndPricing,
            hasCreatedIntegration: !!hubspotIntegrationSettings?.length || !!rssIntegrationSettings?.length
        });
        setPaymentTierAccessLevel(accessLevel);

        // * set human readable tier name
        const tierName = getPlanTierName(accessLevel);
        setPaymentTierName(tierName);
    }, [
        bypassPaymentPortal,
        subscription,
        isNewUser,
        successNarrations,
        hasVisitedPlansAndPricing,
        hubspotIntegrationSettings,
        rssIntegrationSettings
    ]);

    // intialize paywalls for the user's current tier
    useEffect(() => {
        // explicitly check for undefined because 0 is a valid value
        if (paymentTierAccessLevel !== undefined) {
            const _isFeaturePaywalled = getAllFeaturePaywalls(paymentTierAccessLevel);
            setIsFeaturePaywalled(_isFeaturePaywalled);
        }
    }, [paymentTierAccessLevel]);

    useEffect(() => {
        if (isFeaturePaywalled.UNINTRUSIVE_PAGE_LEAVE) {
            const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                // * modern browsers will show a generic message, and we can't customize it. This is a fallback for older browsers
                event.returnValue = LEAVE_FIRST_USE_MODAL_MESSAGE;
            };
            window.addEventListener('beforeunload', beforeUnloadHandler);
            return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
        }
    }, [isFeaturePaywalled]);

    // * can call any exposed set functions when you need the flag updated locally without refreshing posthog flags, or can call refreshAllFeatureFlags to refresh all flags from Posthog
    return (
        <FeatureFlagsContext.Provider
            value={{
                allowSectionedNarrationForm,
                hideAutoNarrations,
                allowYoutubeIntegrationV1,
                showWordsmithParameters,
                showNarrationShareButton,
                allowTransformedAudio,
                isEnabledAudioStudio,
                bypassPaymentPortal,
                hasDistributedWidget,
                setHasDistributedWidget,
                hasSharedOnSocialMedia,
                setHasSharedOnSocialMedia,
                hasDownloadedAudio,
                setHasDownloadedAudio,
                paymentTierAccessLevel,
                isAtCapacityOnAudioStudio,
                usedAudioStudioNarrationCapacity,
                availableAudioStudioNarrationCapacity,
                allowUnlimitedAudioStudio,
                setAllowUnlimitedAudioStudio,
                paymentTierName,
                isFeaturePaywalled,
                setHasVisitedPlansAndPricing,
                hasSkippedTutorial,
                setHasSkippedTutorial,
                isUnlockAllAnalytics,
                showRewriteScript,
                refreshAllFeatureFlags: handleFeatureFlagsLoad
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export default FeatureFlagsProvider;
