import userflow from 'userflow.js';
import { User } from 'firebase/auth';
import moment from 'moment';
import { formatSentryError } from '../../helpers/telemetryHelpers';
import { captureException } from '@sentry/nextjs';

export const initUserflowUser = (user: User) => {
    const USERFLOW_TOKEN = process.env.USERFLOW_TOKEN as string;

    try {
        const email = user.email as string;

        // Format the date in ISO 8601 format to meet userflow requirements
        const creationTimeString = user.metadata.creationTime; // Get from Firebase
        const iso8601CreationTime = moment(creationTimeString).format();

        userflow.init(USERFLOW_TOKEN);
        userflow.identify(user.uid, {
            name: user.displayName,
            email: email,
            signed_up_at: iso8601CreationTime
        });
        console.log('--------USERFLOW has been initalized---------');
    } catch (error) {
        const { message, cause } = formatSentryError('Error initializig Userflow', 'initUserflowUser', error);
        captureException(new Error(message, cause));
    }
};
