import { defineStyleConfig } from '@chakra-ui/react';

export const stepperTheme = defineStyleConfig({
    baseStyle: {
        indicator: {
            borderColor: 'brand.darkComponentOutline'
        },
        separator: {
            bgColor: 'brand.darkComponentOutline'
        }
    }
});
