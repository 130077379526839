import { SupportedISO639LanguagesType, SupportedSampleTextAudioLanguagesType } from '../../types/types';

export const SHARE_PAGE_ERROR_MSG = 'There was an error retrieving this narration, please make sure you copied the link properly and try again';

export const CREATE_NARRATION_BTN_TEXT = 'Generate Audio';

export const CHAR_LIMIT_ERROR_MESSAGE = 'Too many characters';

export const IMAGE_UPLOAD_ERROR_MESSAGE_MAP: Record<number, string> = {
    413: 'Image is too large. Please choose a file under 4MB.'
};

export const LEAVE_FIRST_USE_MODAL_MESSAGE =
    'Are you sure you want to leave? You are currently in a tutorial. Complete the tutorial to unlock all available features.';

// * NOTE: We will have to add translations whenever we add a new language to our voices collection
export const DEFAULT_BLOCK_INTRO_MESSAGES: Record<SupportedISO639LanguagesType, string> = {
    en: 'You are listening to the audio version of $title.',
    ar: '.$title أنت تستمع إلى النسخة الصوتية من',
    it: 'Stai ascoltando la versione audio di $title.',
    ko: '지금 $title 의 오디오 버전을 듣고 계십니다.',
    ja: '$title のオーディオ バージョンを聞いています。',
    de: 'Sie hören die Audioversion von $title',
    pt: 'Você está ouvindo a versão em áudio de $title.',
    nl: 'Je luistert naar de audioversie van $title.',
    zh: '您正在收听 $title 的音频版本。',
    fr: 'Vous écoutez la version audio de $title.',
    es: 'Estás escuchando la versión en audio de $title.'
};

// * NOTE: We will have to add translations whenever we add a new language to our voices collection
export const DEFAULT_BLOCK_OUTRO_MESSAGES: Record<SupportedISO639LanguagesType, string> = {
    en: 'Thank you for listening to the audio version of $title.',
    ar: '.$title شكرًا لك على الاستماع إلى النسخة الصوتية من',
    it: 'Grazie per aver ascoltato la versione audio di $title.',
    ko: '$title 의 오디오 버전을 들어주셔서 감사합니다.',
    ja: '$titleの音声バージョンをお聴きいただきありがとうございます。',
    de: 'Vielen Dank, dass Sie sich die Audioversion von $title angehört haben.',
    pt: 'Obrigado por ouvir a versão em áudio de $title.',
    nl: 'Bedankt voor het luisteren naar de audioversie van $title.',
    zh: '感谢您收听 $title 的音频版本。',
    fr: "Merci d'avoir écouté la version audio de $title.",
    es: 'Gracias por escuchar la versión en audio de $title.'
};

// * NOTE: We will have to add translations whenever we add a new language to our voices collection
// * This array is used to determine which languages are supported for the sample audio studio for now.
export const SAMPLE_AUDIO_STUDIO_SUPPORTED_LANGUAGES = ['en', 'es', 'pt', 'fr'] as const;

export const SAMPLE_AUDIO_STUDIO_TITLE_BY_LANGUAGE: Record<SupportedSampleTextAudioLanguagesType, string> = {
    en: 'Getting started with Adauris.',
    es: 'Empezando con Adauris.',
    pt: 'Introdução ao Adauris.',
    fr: 'Débuter avec Adauris.'
};

export const SAMPLE_AUDIO_STUDIO_TEXT_BY_LANGUAGE: Record<SupportedSampleTextAudioLanguagesType, string> = {
    en: "Congratulations, you just made your first audio file! We hope you're liking how this sounds and are starting to imagine how audio can benefit you.\nThis audio can now be shared with your audience or colleagues through podcasting platforms like Spotify or by sending a shareable link to them, or by four of our other distribution options.\nOnce you've shared the audio you can check back to see how many people have listened, for how long, and if you've shared multiple audio episodes you can see which ones are most popular.\nAnd if you want this audio to sound even more special, there are a lot more edits you can make. You can click on the \"Edit audio\" button below to add background music, change my voice, and add additional sections.\nIf you have any questions, don't hesitate to reach out to us at contact at adauris dot AI and because you stuck around to the end of this episode one of the founders will get back to you!",
    es: '¡Felicitaciones, acaba de crear su primer archivo de audio! Esperamos que te guste cómo suena y estés empezando a imaginar cómo el audio puede beneficiarte.\nEste audio ahora se puede compartir con su audiencia o colegas a través de plataformas de podcasting como Spotify o enviándoles un enlace para compartir, o mediante cuatro de nuestras otras opciones de distribución.\nUna vez que haya compartido el audio, podrá volver a consultarlo para ver cuántas personas lo han escuchado, durante cuánto tiempo y, si ha compartido varios episodios de audio, podrá ver cuáles son los más populares.\nY si desea que este audio suene aún más especial, hay muchas más ediciones que puede realizar. Puede hacer clic en el botón "Edit Audio" a continuación para agregar música de fondo, cambiar mi voz y agregar secciones adicionales.\nSi tiene alguna pregunta, no dude en comunicarse con nosotros en contact en adauris dot AI y, debido a que se quedó hasta el final de este episodio, ¡uno de los fundadores se comunicará con usted!',
    pt: 'Parabéns, você acabou de criar seu primeiro arquivo de áudio! Esperamos que você esteja gostando do som e começando a imaginar como o áudio pode beneficiá-lo.\nEste áudio agora pode ser compartilhado com seu público ou colegas por meio de plataformas de podcasting como o Spotify ou enviando um link compartilhável para eles, ou por quatro de nossas outras opções de distribuição.\nDepois de compartilhar o áudio, você pode verificar quantas pessoas ouviram, por quanto tempo e, se compartilhou vários episódios de áudio, poderá ver quais são os mais populares.\nE se você quiser que esse áudio soe ainda mais especial, há muito mais edições que você pode fazer. Você pode clicar no botão "Edit audio" abaixo para adicionar música de fundo, alterar minha voz e adicionar seções adicionais.\nSe você tiver alguma dúvida, não hesite em entrar em contato conosco pelo contat em adauris dot AI e, como você ficou até o final deste episódio, um dos fundadores entrará em contato com você!',
    fr: "Félicitations, vous venez de créer votre premier fichier audio ! Nous espérons que vous aimez ce son et que vous commencez à imaginer les avantages que l'audio peut vous apporter.\nCet audio peut désormais être partagé avec votre public ou vos collègues via des plateformes de podcasting comme Spotify ou en leur envoyant un lien partageable, ou via quatre de nos autres options de distribution.\nUne fois que vous avez partagé l'audio, vous pouvez vérifier combien de personnes l'ont écouté, pendant combien de temps, et si vous avez partagé plusieurs épisodes audio, vous pouvez voir lesquels sont les plus populaires.\nEt si vous souhaitez que cet audio soit encore plus spécial, vous pouvez apporter de nombreuses autres modifications. Vous pouvez cliquer sur le bouton \"Edit Audio\" ci-dessous pour ajouter une musique de fond, modifier ma voix et ajouter des sections supplémentaires.\nSi vous avez des questions, n'hésitez pas à nous contacter à contact at adauris dot AI et comme vous êtes resté jusqu'à la fin de cet épisode, l'un des fondateurs vous répondra !"
};

export const HTTP_ERROR_MESSAGE = 'URL is incomplete, missing "http://" or “https://”.';
export const INVALID_URL_ERROR_MESSAGE = 'URL is invalid. Please ensure you copied the URL correctly.';
export const DEFAULT_ERROR_URL_EXTRACT_MESSAGE = 'Please try again. If the problem persists then please contact contact@adauris.ai for support.';
