import axios from 'axios';
import { GroupInterface } from '../types/types';
import { formatSentryError } from './telemetryHelpers';
import { captureException } from '@sentry/nextjs';

export const identifyPosthogGroup = async (group: GroupInterface) => {
    try {
        await axios.post('/api/v2/posthog/group-identify/post', { group });
    } catch (error) {
        const { message, cause } = formatSentryError('Error identifying posthog group', 'identifyPosthogGroup', error);
        captureException(new Error(message, cause));
    }
};
