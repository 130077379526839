import {
    FirestoreDocumentIdType,
    NarrationBlockInterface,
    NarrationInterface,
    NarrationMethodEnum,
    NarrationPostPayloadInterface,
    NarrationTypeEnum,
    StatusEnum,
    SupportedISO639LanguagesType,
    VoiceCodeType
} from '../types/types';
import { createNarrationDocument, updateNarrationDocument } from './firestoreHelpers';
import { DEFAULT_BLOCK_INTRO_MESSAGES, DEFAULT_BLOCK_OUTRO_MESSAGES } from '../utils/constants/copyConstants';
import { CharStatsInterface } from '../types/types';

interface UpdateOrCreateBlocksNarrationDocumentProps {
    documentId: FirestoreDocumentIdType | null;
    projectId: FirestoreDocumentIdType;
    title: string;
    audioUrl: string | null;
    status: StatusEnum.Success | StatusEnum.Draft;
    blocks: NarrationBlockInterface[];
    originalText: string;
    sourceUrl: string | null;
    image: NarrationInterface['image'];
    language: NarrationInterface['sourceLanguage'];
    mutateNarrations: () => void;
    narrationType?: NarrationTypeEnum;
    narrationMethod?: NarrationMethodEnum;
    duration: number;
    stats?: CharStatsInterface;
}
export const updateOrCreateBlocksNarrationDocument = async ({
    documentId,
    projectId,
    title,
    audioUrl,
    status,
    blocks,
    originalText,
    sourceUrl,
    image,
    language,
    mutateNarrations,
    narrationType,
    narrationMethod,
    duration,
    stats
}: UpdateOrCreateBlocksNarrationDocumentProps): Promise<FirestoreDocumentIdType> => {
    const partialPayload: any = {
        projectId: projectId,
        audio: audioUrl,
        title: title,
        status: status,
        blocks: blocks,
        originalText: originalText,
        image: image,
        sourceLanguage: language,
        duration: duration,
        stats: stats
    };

    // * sourceType should already be set in the narration doc when just updating the document
    if (narrationType) {
        // * at some point we seem to have used sourceType as narrationType in the documents, but narrationType in audio creation pipeline
        partialPayload.sourceType = narrationType;
    }
    // * narrationMethod should already be set in the narration doc when just updating the document
    if (narrationMethod) {
        partialPayload.narrationMethod = narrationMethod;
    }
    if (sourceUrl) {
        partialPayload.sourceUrl = sourceUrl;
    } else {
        partialPayload.sourceUrl = '';
    }

    if (documentId) {
        await updateNarrationDocument(documentId, partialPayload, mutateNarrations);
        return documentId;
    } else {
        const postPayload: NarrationPostPayloadInterface = {
            ...partialPayload,
            // * NOTE THE FOLLOWING VALUES ARE HARD CODED FOR NOW
            publishDate: new Date().toISOString(),
            narrationSettings: null,
            version: 3,
            siteName: null
        };
        const newDocumentId = await createNarrationDocument(postPayload, mutateNarrations);
        return newDocumentId;
    }
};

/**
 * Remove empty blocks from the blocks array. An empty block is a block with no text and no audioUrl
 * @param blocks
 * @returns NarrationBlockInterface[]
 */
export const removeEmptyBlocks = (blocks: NarrationBlockInterface[]): NarrationBlockInterface[] => {
    return blocks.filter((block) => block.text !== '' || block.audioUrl !== null);
};

export const getDefaultIntroMessage = (title: string, language: string): string => {
    let messageWithTitleVar = '';
    if (language in DEFAULT_BLOCK_INTRO_MESSAGES) {
        messageWithTitleVar = DEFAULT_BLOCK_INTRO_MESSAGES[language as SupportedISO639LanguagesType];
    } else {
        messageWithTitleVar = DEFAULT_BLOCK_INTRO_MESSAGES['en'];
    }

    const message = messageWithTitleVar.replace('$title', title);
    return message;
};

export const getDefaultOutroMessage = (title: string, language: string): string => {
    let messageWithTitleVar = '';
    if (language in DEFAULT_BLOCK_OUTRO_MESSAGES) {
        messageWithTitleVar = DEFAULT_BLOCK_OUTRO_MESSAGES[language as SupportedISO639LanguagesType];
    } else {
        messageWithTitleVar = DEFAULT_BLOCK_OUTRO_MESSAGES['en'];
    }
    const message = messageWithTitleVar.replace('$title', title);
    return message;
};

export const addDefaultIntroAndOutroBlocks = (
    blocks: NarrationBlockInterface[],
    title: string,
    voice: VoiceCodeType,
    language: SupportedISO639LanguagesType
): NarrationBlockInterface[] => {
    // * get language specific default intro and outro text
    const introText = getDefaultIntroMessage(title, language);
    const outroText = getDefaultOutroMessage(title, language);

    // * append the default intro block
    const introBlock: NarrationBlockInterface = {
        text: introText,
        voice: voice,
        intro: null,
        outro: null,
        backgroundTrack: null,
        audioUrl: null,
        duration: 0,
        postPauseLengthMilliseconds: null
    };
    // * append the default outro block
    const outroBlock: NarrationBlockInterface = {
        text: outroText,
        voice: voice,
        intro: null,
        outro: null,
        backgroundTrack: null,
        audioUrl: null,
        duration: 0,
        postPauseLengthMilliseconds: null
    };

    return [introBlock, ...blocks, outroBlock];
};

export const getBlockSectionTitle = (
    blockIndex: number,
    blocksLength: number,
    firstBlockIsDefaultIntro: boolean,
    lastBlockIsDefaultOutro: boolean
): string => {
    if (lastBlockIsDefaultOutro && blockIndex === blocksLength - 1) {
        return 'Outro';
    } else if (firstBlockIsDefaultIntro) {
        if (blockIndex === 0) {
            return 'Intro';
        } else {
            // * if the first block is the default intro, then we can return index as section since index 0 is the intro, and index 1 is the first section
            return `Section ${blockIndex}`;
        }
    } else {
        // * if the first block is not the default intro, then we can return index + 1 as section since index 0 is the first section
        return `Section ${blockIndex + 1}`;
    }
};
