import { defineStyleConfig } from '@chakra-ui/react';

export const linkTheme = defineStyleConfig({
    baseStyle: {
        textDecoration: 'underline'
    },
    variants: {
        blue: {
            color: 'blue.400'
        }
    }
});
