import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { AuthContextInterface, FirestoreDocumentIdType, GroupInterface, ProjectInterface } from '../types/types';
import { EVENT_NAME, USER_PROPERTY_NAME } from '../utils/constants/postHogConstants';
import { registerPosthogEvent } from './postHogEventHelpers';
import { formatSentryError } from './telemetryHelpers';

// ! this function will need to be adjusted once we support multiple projects
export const getProjectByGroupId = async (group: GroupInterface | null) => {
    if (group) {
        try {
            const response = await axios.get(`/api/v2/projects/get-project/groups/${group.id}`);
            const projects = response.data as ProjectInterface[];

            if (!projects.length) {
                throw new Error('No projects returned');
            }
            const firstProject = projects[0];
            return firstProject;
        } catch (err) {
            const { message, cause } = formatSentryError('error hitting /api/v2/projects/get-project/groups/', 'getProjectByGroupId', err);
            captureException(new Error(message, cause));
            return null;
        }
    } else return null;
};

export const updateProject = async (
    projectId: FirestoreDocumentIdType,
    payload: Partial<Omit<ProjectInterface, 'id'>>
): Promise<FirestoreDocumentIdType> => {
    try {
        const response = await axios.patch(`/api/v2/projects/patch-project/${projectId}`, payload);
        return response.data as FirestoreDocumentIdType;
    } catch (err) {
        const { message, cause } = formatSentryError('error hitting /api/v2/projects/patch-project/', 'updateProject', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const patchProjectName = async (
    project: ProjectInterface | null,
    projectName: string,
    fetchAndUpdateProject: AuthContextInterface['fetchAndUpdateProject']
) => {
    try {
        if (!project?.id) {
            throw new Error('Project ID not found in patchProjectName');
        }
        const updatedProject = {
            ...project,
            name: projectName
        };

        // * patch the document
        await updateProject(project.id, updatedProject);

        // * refresh local state
        fetchAndUpdateProject();

        // * updated the user property in posthog
        registerPosthogEvent(EVENT_NAME.set, {
            $set: {
                [USER_PROPERTY_NAME.workspaceName]: projectName
            }
        });
    } catch (error) {
        const { message, cause } = formatSentryError('Error patching project', 'patchProjectName', error);
        captureException(new Error(message, cause));
    }
};
